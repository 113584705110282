<template>
  <div>
    <a-card class="card">
      <div class="title">
        <a-button-group>
          <a-button v-for="(item,index) in allInfo" :key="item.id" :type="indexBtn === index && 'primary' || ''" @click="cut(item, index)">
            {{ item.agrtViewUrl ? '平台合同' : item.fileName }}
          </a-button>
        </a-button-group>
        <a-popconfirm v-if="allInfo.length && allInfo.length > 1 && !allInfo[indexBtn].agrtViewUrl" :title="`是否要删除${allInfo[indexBtn].agrtViewUrl ? '平台合同' : allInfo[indexBtn].fileName}？`" @confirm="delContract">
          <a-button type="danger">删除</a-button>
        </a-popconfirm>
      </div>

      <div style="padding: 24px">
        <template v-if="info.agrtViewUrl">
      <img class="contract-img" src="@/assets/images/u145.png" alt="">
      <a-row type="flex" align="middle" justify="space-between" style="padding-left: 52px;">
        <a-col>
          <a-space :size="32">
            <span class="contract-title">合同编号：{{info.contractNo}}</span>
            <span>发起时间：{{info.createdTime}}</span>
          </a-space>
        </a-col>
        <a-col>
          <a-space>
            <!-- <a-popconfirm title="请确认是否要删除重发" @confirm="resend">
              <a-button type="primary">删除重发</a-button>
            </a-popconfirm> -->
            <a-button type="primary" v-if="info.id" @click="download" :loading="downloadLoading" :disabled="!(info.purchaserSealStatus == 'SIGNED' || info.purchaserSignerStatus == 'SIGNED' || info.supplierSignerStatus == 'SIGNED')">下载文件</a-button>
            <a-button type="primary" :loading="voucherLoading" @click="voucher" v-if="info.supplierSignerStatus == 'SIGNED' && info.purchaserSealStatus == 'SIGNED'&& info.purchaserSignerStatus == 'SIGNED'">电子存证</a-button>
          </a-space>
        </a-col>
      </a-row>

      <a-table
        class="table"
        bordered
        :pagination="false"
        size="small"
        :data-source="data"
        :columns="orderColumns"
        :rowKey="(record, index) => {return index}"
        :loading="loading">
        <template slot="status" slot-scope="text">
          <a-space v-if="text === 'UNSIGNED'" class="font-no">
            <a-icon type="info-circle"/>未签
          </a-space>
          <a-space v-if="text === 'SIGNED'" class="font-success">
            <a-icon type="check-circle"/>已签
          </a-space>
        </template>
        <template slot="action" slot-scope="text,record,index">
          <a-space v-if="record.status === 'UNSIGNED'&&index==2" >
            <a  @click="copyActiveCode(record)">签属链接</a>
            <!-- <a>签署二维码</a> -->
            <a-popconfirm  title="请确认是否重发短信" @confirm="send(index)">
                <a>重发短信</a>
            </a-popconfirm>
          </a-space>

        </template>
      </a-table>
      </template>

      <a-tabs>
        <a-tab-pane key="1" tab="合同内容">
          <iframe v-if="agrtViewUrl.indexOf('.pdf') !== -1 || agrtViewUrl.indexOf('.xls') !== -1 || agrtViewUrl.indexOf('.xlsx') !== -1 || agrtViewUrl.indexOf('.docx') !== -1 || agrtViewUrl.indexOf('.doc') !== -1" :src="agrtViewUrl.indexOf('.pdf') !== -1 ? agrtViewUrl : ('https://view.officeapps.live.com/op/view.aspx?src=' + agrtViewUrl)" style="width:100%;height:800px;margin-top:30px;border:1px solid #f2f2f2" frameborder="0"></iframe>
              <img v-else :src="agrtViewUrl" alt="" style="width: 100%">
        </a-tab-pane>
        <!-- <a-tab-pane key="2" tab="全证据链">
          全证据链
        </a-tab-pane> -->
      </a-tabs>
      </div>
    </a-card>
  </div>
</template>

<script>
const orderColumns = [
  {
    dataIndex: 'identity',
    align: 'center',
    colSpan:0,
    customRender: (value, row, index) => {
      const obj = {
        children: value,
        attrs: {}, 
        style:{
          fontWeight:700
        }
      }
      if (index === 0) {
        obj.attrs.rowSpan = 2
      }
      if (index === 1) {
        obj.attrs.rowSpan = 0
      }
      return obj
    }
  },
  {
    title: '交易方',
    dataIndex: 'trading',
    align: 'center',
    colSpan:2,
    customRender: (value, row, index) => {
      const obj = {
        children: value,
        attrs: {}
      }
      if (index === 0) {
        obj.attrs.rowSpan = 2
      }
      if (index === 1) {
        obj.attrs.rowSpan = 0
      }
      return obj
    }
  },
  {
    title: '签署人',
    dataIndex: 'signtory',
    align: 'center'
  },
  {
    title: '邮箱或手机号',
    dataIndex: 'relation',
    align: 'center'
  },
  {
    title: '签署状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'center'
  },
  {
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
]
import { findcontract, send, anew, download, voucher, deleteContract } from "@/api/order";
import cookie from '@/utils/js-cookie.js'
import { supplierSignLink, purchaserSignLink } from '@/api/signLink'
import Clipboard from 'clipboard-js'
export default {
    data () {
        return {
            data: [],
            orderColumns,
            loading: false,
            allInfo: [],
            agrtViewUrl: '',
            statusLoading: false,
            info: {},
            indexBtn: 0,
            voucherLoading: false,
            downloadLoading: false,
            copyLoading: false
        }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        findcontract(this.$route.query.id).then(res => {
          console.log(res)
          if (res.code === 200) {
            this.allInfo = res.data.contract ? [res.data.contract, ...res.data.contractSups] : res.data.contractSups
            this.agrtViewUrl = this.allInfo[0] && (this.allInfo[0].agrtViewUrl || this.allInfo[0].fileViewUrl)
            this.allInfo.forEach(item => {
              if (item.agrtViewUrl) {
                this.info = item
                this.infoType(item)
              }
            })
          } else {
            this.$message.error(res.message)
          }
        })
      },
      infoType (data) {
        this.data = [
          {
            identity: '买方',
            trading: `${data.purchaserName}`,
            signtory: `${data.purchaserSealName}`,
            relation: `${data.purchaserSealEmail}`,
            status: `${data.purchaserSealStatus}`,
            entityCharacter: 'S'
          },
          {
            identity: '买方',
            trading: `买方：${data.purchaserName}`,
            signtory: `${data.purchaserSignerName}`,
            relation: `${data.purchaserSignerMobile}`,
            status: `${data.purchaserSignerStatus}`,
            entityCharacter: 'P',
            agrtSignUrl: `${data.purchaserAgrtSignUrl}`
          },
          {
            identity: '卖方',
            trading: `${data.supplierName}`,
            signtory: `${data.supplierSignerName}`,
            relation: `${data.supplierSignerMobile}`,
            status: `${data.supplierSignerStatus}`,
            entityCharacter: 'S',
            agrtSignUrl: `${data.supplierAgrtSignUrl}`
          }
        ]
      },
      cut (item, index) {
        this.indexBtn = index
        this.info = item
        this.statusLoading = true
        this.$nextTick(() => {
          this.statusLoading = false
          this.agrtViewUrl = item.agrtViewUrl || item.fileViewUrl
        })
      },
      // 重发短信
      send(index) {
        send(this.info.id, index === 2 ? 'S' : 'P').then(res => {
          if (res.code === 200) {
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        })
      },
      // 删除重发
      resend() {
        anew(this.info.id).then(res => {
          if (res.code === 200) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      },
      // 合同下载
      download() {
        this.downloadLoading = true
        download(this.info.id).then(res => {
          this.downloadLoading = false
          if (res.code === 200) {
            window.location.href = res.data
          } else {
            this.$message.error(res.message)
          }
        })
      },
      // 电子凭证下载
      voucher() {
        const data = cookie.get('checkEntity') || {}
        if (data.entityCharacter) {
          this.voucherLoading = true
          voucher(this.info.id, data.entityCharacter).then(res => {
            this.voucherLoading = false
            if (res.code === 200) {
              console.log(res);
              window.open(res.data)
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          this.$message.error('请切换身份进行访问')
        }
      },
        // 复制签署链接
    copyActiveCode (row) {
      console.log(row);
        if (this.copyLoading) return
        this.copyLoading = true
        row.entityCharacter === 'S'
        ? supplierSignLink({ orderId: this.$route.query.id }).then(res => {
          this.copyLoading = false
          if (res.code === 200) {
          Clipboard.copy(res.data)
          this.$notification.success({
            message: '成功',
            description: '复制成功'
          })
        } else {
          this.$message.error(res.message)
        }
        })
        : purchaserSignLink({ orderId: this.$route.query.id }).then(res => {
          this.copyLoading = false
          if (res.code === 200) {
          Clipboard.copy(res.data)
          this.$notification.success({
            message: '成功',
            description: '复制成功'
          })
        } else {
          this.$message.error(res.message)
        }
        })
      },
      delContract () {
        deleteContract({ orderId: Number(this.$route.query.id), contractId: this.allInfo[this.indexBtn].id, fileSource: this.allInfo[this.indexBtn].fileViewUrl ? 2 : 1 }).then(res => {
          if (res.code === 200) {
            this.$message.success(res.message)
            this.indexBtn = 0
            if (this.allInfo[this.indexBtn].fileViewUrl) {
              this.info = {}
            }
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      },
    },
}
</script>

<style lang="less" scoped>
.card {
    margin-bottom: 32px;
    position: relative;
}
.contract-img {
    position: absolute;
    left: 26px;
    width: 32px;
    height: 32px;
}
.title {
  display: flex;
  justify-content: space-between;
}
.contract-title {
    font-weight: 700;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 20px;
    line-height: 28px;
}
.table {
  margin: 24px 0;
}
/deep/ .ant-card-bordered {
    border: none;
    position: relative;
}
/deep/ .ant-col {
    margin-bottom: 8px;
}
/deep/ .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}
.font-no {
  color: #aaa;
}
.font-success {
  color: #8080FF;
}
/deep/ .ant-table-align-center{
  font-weight: 700;
}
/deep/ .ant-card-body {
  padding: 0;
}
</style>
