<template>
    <div class="no-result">
        <div class="result-page">
            <div>
                <p><a-icon type="shop" class="icon"/></p>
                <p><span>抱歉，没有获取到相关店铺信息</span></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            defineX: 0,
            translateX: 0,
            list: ['全部','低压聚乙烯','尼龙','聚丙烯','聚氯乙烯','聚碳酸酯','聚酯','高压聚乙烯','其他']
        }
    },
    mounted() {
        this.translateX = (this.$refs.recommend && this.$refs.recommend.clientWidth) || 0
        console.log('translateX', this.translateX)
    },
}
</script>

<style lang="less" scoped>
    .no-result {
        margin-top: 20px;
        margin-bottom: 32px;
        .error-font {
            color: @error;
        }
        .result-page {
            margin: 0 auto;
            width: 1440px;
            background: #fff;
            height: 600px;
            border-width: 1px;
            border-style: solid;
            border-color: @border;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #999;
            .icon {
                font-size: 96px;
                margin-bottom: 12px;
            }
            .recommend {
                margin-top: 48px;
                .font-space {
                    color: #999;
                }
            }
            p {
                text-align: center;
                font-size: 16px;
                margin-bottom: 16px;
            }
        }
    }
</style>