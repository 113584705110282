<template>
  <div class="cost-box">
    <a-card title="订单成本">
      <div class="cost-mes">本区域需要卖方自主申报成本，由代账托管公司为其进行报税，卖方需根据代账托管公司提供的该季度税款清单进行缴纳。<span class="impor">* 订单成本需要考虑货物收购成本及人工、水电、运费、损耗、附加税、个税等部分</span></div>
      <div class="cost-form">
        <a-space >
          <template>
            利润率：
            <a-input-number
              id="inputNumber"
              v-model="profitMargin"
              :precision="2"
              :placeholder="`>=4`"
              style="width:120px"
              @blur="profFocus=false;changeProfitMargin(2,profitMargin)"
              @focus="profFocus=true"
              :disabled="costFocus"
            />
            %
          </template>

         
          <template>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;自主申报成本：
            <a-input-number 
              id="inputNumber" 
              v-model="costAmount" 
              :precision="2" 
              :placeholder="`<${costCeiling}`" 
              style="width:120px" 
              @blur="costFocus=false;changeProfitMargin(1,costAmount)"
              @focus="costFocus=true"
              :disabled="profFocus"
            />
            元
          </template>
          <!-- <span v-else>
            {{ costAmount||costAmount==0?`${costAmount}元`:'已申报' }}
          </span> -->

          <span class="impor" v-if="lastUpdatedTime&&accountName">上次修改时间：{{lastUpdatedTime}}, 修改人：{{accountName}}</span>
        </a-space>
        <a-button type="primary"  :loading="loading" @click="isVisible=true">
          保存
        </a-button>
      </div>
    </a-card>

    <!-- 注销纳税人弹窗 -->
    <a-modal
      v-model="isVisible"
      title="提示"
      centered
      class="off-model"
      >
      
      <div class="modal">
          <p><a-icon type="exclamation-circle" theme="filled" :style="{ color: '#fa8c16' }" /> 请您仔细确认填入的利润率或成本金额是否准确，<span style="color:#D9001B">因不准确的利润或成本金额导致缴税问题将由您自行负责</span></p>
      </div>
      <template slot="footer">
          <a-button key="back" @click="isVisible = false">
              取消
          </a-button>
          <a-button key="submit" type="primary" @click="onChangeQuantity">
              确定
          </a-button>
      </template>
    </a-modal>

  </div>
</template>
<script>
import { ordersCostdecQuery, ordersCostdec, ordersCostdecEval } from "@/api/order";
export default {
  data(){
    return {
      costAmount:'', // costAmount
      lastUpdatedTime:'', //最后修改时间
      accountName:'', // 修改人
      costCeiling:'',// 最高价
      loading:false,
      invoiceStatus:'',// 开票状态

      profitMargin: '',
      costFocus: false,
      profFocus: false,
      isVisible:false
    }
  },
  mounted(){
    console.log('getOrdersCostdecQuery')
    this.getOrdersCostdecQuery()
  },
  computed:{
    getType(){
      return this.invoiceStatus!='PROCESSING'&&this.invoiceStatus!='INVOICED'&&this.invoiceStatus!='REVIEWED'&&this.invoiceStatus!='WAIT_INVOICE'
    },
  },
  methods:{
    changeProfitMargin (estimateModel, value) {
      const { id } = this.$route.query
      if (estimateModel === 2) {
        if(!value&&value!=0){
          return
        }
        value = value / 100
      }
      ordersCostdecEval({
        estimateModel,
        value,
        orderId: id
      }).then(res => {
        if (res.code === 200) {
          if (estimateModel === 2) {
            this.costAmount = res.data.costAmount
          } else {
            this.profitMargin = res.data.profitMargin * 100
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 编辑申报成本
    onChangeQuantity(){
      let {costAmount, profitMargin } = this;
      let { id } = this.$route.query
      
      if(this.loading) return
      if (profitMargin <= 0 || profitMargin > 100) {
        this.$message.error('成本申报业务异常：利润率必须大于0%、小于等于100%！')
        return
      }
      if(!costAmount && costAmount !== 0){
        this.$message.error('请输入申报成本！')
        return
      }
      this.loading = true
      ordersCostdec({
        orderId:id,
        costAmount
      }).then(res=>{
        this.isVisible = false
        if(res.code === 200){
          this.$message.success(res.message)
          this.getOrdersCostdecQuery()
        }else {
          this.$message.error(res.message)
        }
        setTimeout(() => {
            this.loading = false
        }, 500);
      })
    },
    getOrdersCostdecQuery(){
      let { id } = this.$route.query
      ordersCostdecQuery({
        orderId:id
      }).then(res=>{
        if(res.code ===200){
          this.costAmount = res.data.costAmount
          this.lastUpdatedTime = res.data.lastUpdatedTime
          this.accountName = res.data.accountName
          this.costCeiling = res.data.costCeiling
          this.invoiceStatus = res.data.invoiceStatus
          this.profitMargin = res.data.profitMargin * 100
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.cost-mes{
  border:1px  dashed red;
  border-radius: 8px;
  padding: 10px;
  background-color: rgb(242, 242, 242);
  font-size: 12px;
  
}
.impor{
    color: #B8741A;
  }
.cost-form{
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.cost-box{
  margin-bottom: 30px;
}
</style>