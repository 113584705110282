<template>
  <div class="main">
    <div class="header">
        <div class="l-flex-between width">
            <div>
                <div class="h-hint">再生资源交易综合解决方案</div>
                <p class="h-details">交易、合同、支付、货运、存证、监管一体化合规解决方案</p>
                <div class="h-qr" align="center">
                  <img src="@/assets/images/static/qr/u300.png" alt="">
                  <p>扫码咨询</p>
                </div>
            </div>

            <img class="h-right" src="@/assets/images/static/u1156.png" alt="">
        </div>
    </div>

    <div class="body">
        <p class="fb32">服务流程</p>
        <a-row :gutter="140" class="b-irow" style="margin: 0;margin-top: 120px;">
            <a-col :span="6" align="center">
                <span class="m-title"><span class="m-special">1</span>在线咨询</span>
                <p class="m-details m26">通过平台、微信、电话等方式咨询沟通，为您讲解梳理政策解答疑惑</p>
            </a-col>
            <a-col :span="6" align="center">
                <span class="m-title"><span class="m-special">2</span>制定方案</span>
                <p class="m-details m26">讲解客户目前交易模式的风险，引导客户进行合规化交易</p>
            </a-col>
            <a-col :span="6" align="center">
                <span class="m-title"><span class="m-special">3</span>签订协议</span>
                <p class="m-details m26">确认服务细节，签订采购商整体协议，或供应商入驻签署协议</p>
            </a-col>
            <a-col :span="6" align="center">
                <span class="m-title"><span class="m-special">4</span>无忧服务</span>
                <p class="m-details m26">专人全程跟进落实，真实交易有据可查</p>
            </a-col>
        </a-row>
    </div>

    <div class="foot">
        <div class="container">
            <p class="fb32 mb60"> 91再生交易中心解决方案流程</p>
            <p class="fb18">供货商流程</p>
            <a-row class="mb60" type="flex" justify="center">
              <a-col :span="4" align="center" class="f-col" v-for="(item, index) in footList" :key="index">
                <div class="icon">
                  <img :src="item.image" alt="">
                  <img v-if="footList.length !== index + 1" class="dashed" src="@/assets/images/static/u1185.svg" alt="">
                </div>
                <p>{{item.name}}</p>

                <div v-if="index === 1" style="margin-top: 32px">
                  <div class="icon">
                    <img src="@/assets/images/static/u1190.svg" alt="">
                    <img class="dashed long" src="@/assets/images/static/u1193.svg" alt="">
                  </div>
                  <p>已有个体户，托管代账</p>
                </div>
              </a-col>
            </a-row>
            <p class="fb18">采购商流程</p>
            <a-row type="flex" justify="center" style="margin-bottom: 320px">
              <a-col :span="4" align="center" class="f-col" v-for="(item, index) in footList2" :key="index">
                <div class="icon">
                  <img :src="item.image" alt="">
                  <img v-if="footList.length !== index + 1" class="dashed" src="@/assets/images/static/u1185.svg" alt="">
                </div>
                <p>{{item.name}}</p>

                <div v-if="index === 1" class="down-l">
                  <div class="icon">
                    <img src="@/assets/images/static/u1208.svg" alt="">
                    <img class="dashed d-left" src="@/assets/images/static/u1185.svg" alt="">
                  </div>
                  <p>现有供应商入驻发布</p>
                </div>
                <div v-if="index === 1" class="down-r">
                  <div class="icon">
                    <img src="@/assets/images/static/u1210.svg" alt="">
                    <img class="dashed d-right" src="@/assets/images/static/u1185.svg" alt="">
                  </div>
                  <p>撮合供应商</p>
                </div>
              </a-col>
            </a-row>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
          footList: [
            {
                image: require('@/assets/images/static/u1182.svg'), 
                name: '自然人实名认证',
            },
            {
                image: require('@/assets/images/static/u1186.svg'), 
                name: '工商注册',
            },
            {
                image: require('@/assets/images/static/u1188.svg'), 
                name: '开店入驻',
            },
            {
                image: require('@/assets/images/static/u1194.svg'), 
                name: '发布商品',
            },
            {
                image: require('@/assets/images/static/u1196.svg'), 
                name: '完成订单五流',
            },
            // {
            //     image: require('@/assets/images/static/u1198.svg'), 
            //     name: '代账报税',
            // },
          ],
          footList2: [
            {
                image: require('@/assets/images/static/u1204.svg'), 
                name: '采购商实名入驻',
            },
            {
                image: require('@/assets/images/static/u1206.svg'), 
                name: '采购撮合商品',
            },
            {
                image: require('@/assets/images/static/u1216.svg'), 
                name: '订单支付',
            },
            {
                image: require('@/assets/images/static/u1218.svg'), 
                name: '过磅质检收货',
            },
            // {
            //     image: require('@/assets/images/static/u1220.svg'), 
            //     name: '开票申请',
            // },
            {
                image: require('@/assets/images/static/u1222.svg'), 
                name: '订单五流存证',
            },
          ]
        }
    }
}
</script>

<style lang="less" scoped>
.main {
    background: #fff;
    min-width: 990px;
}
.header {
    height: 410px;
    padding: 0 50px;
    padding-top: 20px;
    background: #f2f2f2;
    overflow: hidden;
    .width {
        min-width: 890px;
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
    }
    .h-hint {
        font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 24px;
        color: #666666;
        line-height: 27px;
        margin-top: 60px;
    }
    .h-details {
        margin-top: 20px;
        margin-bottom: 16px;
        font-family: '华文细黑', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        color: #555555;
        line-height: 27px;
        max-width: 530px;
    }
    .h-qr {
      width: 90px;
      height: 90px;
      img {
        width: 100%;
        height: 100%;
      }
      p {
        font-family: '微软雅黑', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: #7F7F7F;
        text-align: center;
        line-height: 27px;
      }
    }
    .h-right {
        height: 360px;
        // @media screen and (max-width: 1100px){
        //     width: 400px;
        // }
    }
}

.body {
    padding-top: 46px;
    padding-bottom: 80px;
    margin: 0 auto;
    min-width: 990px;
    max-width: 1200px;
    .b-irow {
        margin-top: 100px;
        margin-bottom: 64px;
    }
}

.foot {
    background: #f2f2f2;
    padding-top: 64px;
    .container {
        margin: 0 auto;
        min-width: 990px;
        max-width: 1400px;
    }
    // .f-row {

    // }
    .f-col {
      color: #666666;
      position: relative;
      .down-l {
        position: absolute;
        bottom: -208px;
        left: -80px;
        width: 100%;
        height: 100%;
        .icon {
          width: 72px;
          height: 72px;
        }
      }
      .down-r {
        position: absolute;
        bottom: -208px;
        right: -80px;
        width: 100%;
        height: 100%;
        .icon {
          width: 72px;
          height: 72px;
        }
      }
      .icon {
        width: 96px;
        height: 96px;
        position: relative;
        margin-bottom: 16px;
        img {
          width: 100%;
          height: 100%;
          background: #f2f2f2;
        }
        .dashed {
          width: 115px;
          position: absolute;
          top: 48px;
          height: initial;
          right: -124px;
        }
        .long {
          width: 145px;
          transform: rotate(-36.8deg);
          top: -28px;
          right: -135px;
        }
        .d-left {
          width: 95px;
          transform: rotate(-44.2deg);
          top: -40px;
          left: 30px;
        }
        .d-right {
          width: 95px;
          transform: rotate(45.4deg);
          top: -40px;
          right: 25px;
        }
      }
    }
}

.fb32 {
    font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 32px;
    text-align: center;
}
.mb60 {
  margin-bottom: 60px;
}
.fb18 {
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  color: #666666;
  font-size: 18px;
  text-align: center;
  margin-bottom: 26px;
}
.m26 {
    margin-top: 26px;
}

.m-title {
    font-family: '黑体 Bold', '黑体 Regular', '黑体', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 22px;
    position: relative;
    z-index: 99;
    .m-special {
        user-select: none;
        position: absolute;
        left: 6px;
        top: -70px;
        z-index: -1;
        font-family: 'Fredericka the Great Bold', 'Fredericka the Great Regular', 'Fredericka the Great', sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 120px;
        // letter-spacing: 6px;
        color: #F2F2F2;
    }
}
.m-details {
    font-family: '华文细黑', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: normal;
    color: #666666;
    max-width: 515px;
}
</style>