<template>
  <div>
    <a-card title="流程进度" class="card">
      <a-steps class="pt20">
        <a-step title="创建合同" status="finish" :description="info.createdTime" />
        <a-step title="合同签署" :status="info.contractPro === '100%' && 'finish'" :description="info.contractFinishTime||info.contractPro"/>
        <a-step title="支付" :status="info.paymentPro === '100%' && 'finish'" :description="info.paymentFinishTime||info.paymentPro" />
        <a-step title="物流上传" :status="info.logisticsPro === '100%' && 'finish'" :description="info.logisticsFinishTime||info.logisticsPro" />
        <a-step title="完成" :status="(info.logisticsPro == '100%' && info.contractPro == '100%' && info.paymentPro == '100%') && 'process'" />
      </a-steps>
    </a-card>

    <a-card title="订单内容" class="card">
      <a-table
        :data-source="data"
        :columns="orderColumns"
        :pagination="false"
        :rowKey="(record,index)=>{return index}"
        :loading="loading">
        <template slot="totalAmount" slot-scope="text">
            <span class="red">{{text}}</span>
        </template>
        <template slot="footer">
          <div class="foot-flex">
            <div class="remark-box" v-if="info.remark">
              <div class="remark-title">备注</div>
              <div class="remark-text">{{ info.remark }}</div>
            </div>
            <div class="footer">
              <b>合计</b>
              <b>{{ info.contractAmount && info.contractAmount.toFixed(2) }}</b>
            </div>
          </div>
        </template>
          <template slot="unitPrice" slot-scope="text">
          <span>{{ text && text.toFixed(2) }}</span>
        </template>
      </a-table>
    </a-card>

    <a-card class="card" title="卖家信息">
      <a-row>
        <a-col :span="8">名称：<router-link :to="`/shop/s${info.supplierId}`">{{ info.supplierName }}</router-link></a-col>
        <a-col :span="8">卖家性质：{{ info.supplierType }}</a-col>
        <a-col :span="8">税号：{{ info.supplierUnifiedCode }}</a-col>
        <a-col :span="8">姓名：<a>{{ info.supplierLegalName }}</a></a-col>
        <a-col :span="8">联系电话：{{ info.supplierMobile }}</a-col>
        <a-col :span="8">身份证号：{{ info.supplierIdCard }}</a-col>
      </a-row>
    </a-card>

    <a-card class="card" title="买家信息">
      <a-row>
        <a-col :span="8">名称：<a>{{ info.purchaserCommercialName }}</a></a-col>
        <a-col :span="8">税号：{{ info.purchaserUniformCode }}</a-col>
        <a-col :span="8">
          <a-space :size="24">
            收货地址：{{ info.delyFullAddr }}
          </a-space>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { infoflow } from '@/api/order'
import { type } from '@/utils/constArr'
const orderColumns = [
  {
    title: '商品名称',
    dataIndex: 'mdseName',
    // customRender: (value, row, index) => {
    //   console.log(value, index);
    //   const obj = {
    //     children: value,
    //     attrs: {}
    //   }
    //   if (index === 2) {
    //     obj.attrs.colSpan  = 2
    //   }
    //   return obj
    // }
  },
  {
    title: '发票名称',
    dataIndex: 'invoiceName',
    align: 'left',
    // customRender: (value, row, index) => {
    //   console.log(value, index);
    //   const obj = {
    //     children: value,
    //     attrs: {}
    //   }
    //   if (index === 2) {
    //     obj.attrs.colSpan  = 0
    //   }
    //   return obj
    // }
  },
  {
    title: '规格',
    dataIndex: 'specName',
    align: 'center'
  },
  {
    title: '单价（不含税）',
    dataIndex: 'unitPrice',
    scopedSlots: { customRender: 'unitPrice' },
    align: 'center'
  },
  {
    title: '数量',
    dataIndex: 'quantity',
    align: 'center'
  },
  {
    title: '金额（不含税）',
    dataIndex: 'price',
    scopedSlots: { customRender: 'price' },
    align: 'center'
  },
   {
    title: '增值税（0%）',
    dataIndex: 'vatTaxAmount',
    align: 'center'
  },
  {
    title: '小计（含税费）',
    dataIndex: 'totalAmount',
    scopedSlots: { customRender: 'totalAmount' },
    align: 'right'
  }
]

export default {
  data () {
      return {
          data: [],
          orderColumns,
          loading: false,
          info: {},
      }
  },
  computed: {
    current () {
      if (this.info.contractPro !== '100%') {
        return 1
      } else {
        return 5
      }
    }
  },
  mounted () {
    infoflow(this.$route.query.id).then(res => {
      if (res.code === 200) {
        
        let vatRate = orderColumns.find(item=>item.dataIndex==='vatTaxAmount')
        vatRate.title = `增值税（${res.data.vatRate*100+'%'}）`
        this.orderColumns = orderColumns

        //修改表格标题 添加单位
        this.orderColumns = orderColumns.map(item=>{
            if(item.dataIndex==='quantity'){
              item.title = `数量（${type[res.data.unitsMeasure]}）`
            }
            return item
          })

        this.info = res.data
        this.info.logisticsPro = this.percent(this.info.logisticsPro)
        this.info.contractPro = this.percent(this.info.contractPro)
        this.info.paymentPro = this.percent(this.info.paymentPro)
        this.info.mdseContent.map(item=>{
          this.data.push({ ...item, price: item.taxableAmount.toFixed(2),totalAmount: item.totalAmount.toFixed(2) })
        })
        console.log(this.info.mdseContent)
        // this.data.push({ mdseName: '增值税', quantity: this.info.vatRate*100+'%', price: this.info.vatTaxAmount.toFixed(2) })
        // this.info.remark && this.data.push({ mdseName: '备注：' + this.info.remark})
      }
    })
  },
  methods: {
    percent(val) {
      const num = Number(val.split('%')[0])
      return (num >100 ? 100 : num) + '%'
    }
  }
}
</script>

<style lang="less" scoped>
.card {
    margin-bottom: 20px;
}
.contract-title {
    font-weight: 700;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 20px;
    line-height: 28px;
}
.foot-flex{
  display: flex;
  justify-content: flex-end;
  .remark-box{
    display: flex;
    flex: 1;
    margin-right: 20px;
    .remark-title{
      font-weight: bold;
      margin-right: 10px;
    }
    .remark-text{
      flex: 1;
      color: #333;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    min-width: 150px;
  }
}
/deep/ .ant-card-bordered {
    border: none;
    position: relative;
}
/deep/ .ant-col {
    margin-bottom: 20px;
}
.pt20{
  padding: 20px;
}


.red{
  color: red;
  font-size: 16px;
}
</style>
