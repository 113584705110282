<template>
  <div class="main">
    <div class="header">
        <div class="l-flex-between width">
            <div>
                <div class="h-title"> 91再生交易中心</div>
                <div class="h-hint">再生资源交易综合解决方案</div>
                <p class="h-details">91再生交易中心提供买卖双方实名认证、工商代注册、交易撮合，同时提供电子合同、支付管理、货流管理等交易后续服务。通过订单五流的形式，帮助再生资源企业完成订单凭证电子化，解决再生资源行业合规问题。</p>
            </div>

            <img class="h-right" src="@/assets/images/static/u819.png" alt="">
            <img class="h-back" src="@/assets/images/static/u820.png" alt="">
        </div>
    </div>

    <div class="body">
        <p class="fb32">便捷高效的交易系统</p>
        <a-row type="flex" align="middle" class="b-row">
            <a-col :span="12" align="center">
                <img class="b-img1" src="@/assets/images/static/u938.png" alt="">
            </a-col>
            <a-col :span="12" class="b-col">
                <p class="m-title"><span class="m-special">A</span>交易平台</p>
                <p class="m-details m26">交易平台提供了自然人工商注册申请、买卖双方实名入驻、商品发布、撮合交易、电子合同签署、线上支付管理、发票开具及管理、货流管理等功能。</p>
                <p class="m-details">供应商可发布商品寻找采购商，进行交易资金的合规管理，通过平台了解交易状态、合同、支付记录等。</p>
                <p class="m-details">采购商可通过平台采购所需商品，并确保交易的发票真实性，通过订单五流进行订单凭证的存档，方便后期订单查询管理。</p>
            </a-col>
        </a-row>
        <a-row :gutter="60" class="b-irow" style="margin: 0;margin-top: 100px;margin-bottom: 56px;">
            <a-col :span="6" align="center">
                <span class="m-title"><span class="m-special">1</span>托管服务</span>
                <p class="m-details m26">供应商入驻0门槛，推荐优质采购商，通过全流程信息化方式进行合规交易</p>
            </a-col>
            <a-col :span="6" align="center">
                <span class="m-title"><span class="m-special">2</span>合规支付</span>
                <p class="m-details m26">通过与各大支付渠道、银行的合作，经过央行反洗钱审核，实现大额支付，并提供相应的支付回单证明交易真实性</p>
            </a-col>
            <a-col :span="6" align="center">
                <span class="m-title"><span class="m-special">3</span>隐私保护</span>
                <p class="m-details m26">与其他电商平台不同，采购商携带自己的供应商入驻平台，可选择仅集团内交易，从而保证自己的客户隐私不会被他人获取，供货商发布的商品不会被人抢购</p>
            </a-col>
            <a-col :span="6" align="center">
                <span class="m-title"><span class="m-special">4</span>五流存证</span>
                <p class="m-details m26">为采购商提供订单五流存证，商品信息、合同流、资金流、货流、票流等相关数据、文件、照片内容均会永久存档，方便采购商进行对账，快速检索进行高效核查</p>
            </a-col>
        </a-row>
        <a-row type="flex" align="middle" class="b-row">
            <a-col :span="12" class="b-col">
                <p class="m-title"><span class="m-special">B</span>监管系统</p>
                <p class="m-details m26">平台运营、区域管理、工商管理、税务监管者通过监管系统对工商营业执照申请进行审核、商品及发票分类管理、订单五流管理、订单风险评估、支付管理、物流跟踪监管等功能。</p>
                <p>&#12288;</p>
                <p class="m-details">监管系统提高了交易监管的效率及准确性。</p>
            </a-col>
            <a-col :span="12" align="center">
                <img class="b-img2" src="@/assets/images/static/u960.png" alt="">
            </a-col>
        </a-row>
        <a-row type="flex" align="middle" class="b-row" style="margin-top: 56px">
            <a-col :span="12">
                <img class="b-img3" src="@/assets/images/static/u964.jpg" alt="">
            </a-col>
            <a-col :span="12" class="b-col">
                <p class="m-title"><span class="m-special">C</span>91智能机-再生资源台账系统</p>
                <p class="m-details m26"> 91再生交易中心可接入91智能机，通过物联网方式获取过磅数据，减少回收中心传统收验货繁琐的流程和验收人员的工作量，提高工作效率、保证数据精准性</p>
                <div v-for="(item,index) in bodyList" :key="index">
                    <div class="b-fl"><a-icon type="check-circle" /></div>
                    <div class="b-text">
                        <p><b>{{item.name}}</b></p>
                        <p>{{item.text}}</p>
                    </div>
                </div>
            </a-col>
        </a-row>
    </div>

    <div class="foot">
        <div class="container">
            <p class="fb32"> 91再生交易中心产品优势</p>
            <a-row style="margin: 0;margin-top: 70px" :gutter="100" class="f-row">
                <a-col  class="f-col" :span="6" align="center" v-for="(item, index) in footList" :key="index">
                    <img class="f-img" :src="item.image" alt="">
                    <p class="f-title">{{item.name}}</p>
                    <p class="f-text">{{item.text}}</p>
                </a-col>
            </a-row>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            bodyList: [
                {
                    name: '自动称重过磅',
                    text: '无人值守，自动计算净重，提高过磅称重工作效率'
                },
                {
                    name: '防控作弊行为减少公司损失',
                    text: '接入监控摄像，称重带有重量及照片，通过云端存储确保数据真实性'
                },
                {
                    name: '打通 91再生交易中心',
                    text: '通过磅物流信息快速同步至 91再生交易中心交易订单货流，减少人工操作'
                },
                {
                    name: '自动汇总记账',
                    text: '提供再生资源台账功能，符合40号文台账标准，快捷生成交易台账'
                },
            ],
            footList: [
                
                {
                    image: require('@/assets/images/static/u985.svg'), 
                    name: '方案咨询',
                    text: '提供再生资源客户对新规新政策的解读介绍，提高交易风险防范意识，引导其合规化交易'
                },
                {
                    image: require('@/assets/images/static/u989.svg'), 
                    name: '业务监管',
                    text: '贴合再生资源行业的功能及操作逻辑，帮助回收企业回收业务信息化转变'
                },
                {
                    image: require('@/assets/images/static/u993.svg'), 
                    name: '在线撮合',
                    text: '除促进自身业务的合规交易外，平台依托91再生多年的运营经验与客户量，为平台用户撮合交易'
                },
                {
                    image: require('@/assets/images/static/u997.svg'), 
                    name: '电子合同',
                    text: '提供电子合同签署，实现买卖双方生成订单后手机签署确认，通过互联网法院保全验证，具备与纸制合同一样的法律效应'
                },
                {
                    image: require('@/assets/images/static/u1001.svg'), 
                    name: '线上支付',
                    text: '公对私7*24小时转账支付，安全合规高效，提供支付回单，不收取交易手续费'
                },
                {
                    image: require('@/assets/images/static/u1005.svg'), 
                    name: '物流监管',
                    text: '实时获取货运车辆的北斗导航数据，从而获取运输轨迹，跟踪定位物流信息，同时进行相关照片的存证'
                },
                // {
                //     image: require('@/assets/images/static/u1009.svg'), 
                //     name: '快捷开票',
                //     text: '订单完成后，通过平台监管认定其交易真实性，允许针对该订单上传合规发票，为交易进行记账存证等服务'
                // },
                {
                    image: require('@/assets/images/static/u1013.svg'), 
                    name: '数据安全',
                    text: '平台将交易数据进行区块链存储，防止数据篡改，同时区域、集团数据隔离确保用户隐私'
                },
            ]
        }
    }
}
</script>

<style lang="less" scoped>
.main {
    background: #fff;
    min-width: 990px;
}
.header {
    height: 480px;
    padding: 0 50px;
    background: #f2f2f2;
    overflow: hidden;
    .width {
        min-width: 890px;
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
        .h-back {
            position: absolute;
            top: -214px;
            left: -83px;
            width: 340px;
            height: 340px;
        }
    }
    .h-title {
        font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 40px;
        line-height: 1.2;
    }
    .h-hint {
        font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 20px;
        color: #7F7F7F;
        line-height: 27px;
    }
    .h-details {
        margin-top: 40px;
        font-family: '华文细黑', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        color: #555555;
        line-height: 27px;
        max-width: 530px;
    }
    .h-right {
        height: 480px;
        // @media screen and (max-width: 1100px){
        //     width: 400px;
        // }
    }
}

.body {
    padding-top: 46px;
    padding-bottom: 120px;
    margin: 0 auto;
    min-width: 990px;
    max-width: 1200px;
    .b-img1 {
        width: 357px;
        height: 397px;
    }
    .b-img2 {
        width: 512px;
        max-height: 302px;
        @media screen and (max-width: 1000px){
            width: 495px;
        }
    }
    .b-img3 {
        width: 540px;
        max-height: 453px;
        @media screen and (max-width: 1000px){
            width: 495px;
        }
    }
    .b-row {
        margin-top: 20px;
        .b-col {
            padding-top: 20px;
        }
    }
    .b-irow {
        margin-top: 100px;
        margin-bottom: 56px;
    }
    .b-fl {
        float: left;
        color: #6DBA44;
        line-height: 22px;
        font-size: 16px;
    }
    .b-text {
        margin-top: 10px;
        margin-left: 26px;
        font-family: 'Arial Normal', 'Arial', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        letter-spacing: normal;
        color: #666666;
        line-height: 22px;
    }
}

.foot {
    background: #f2f2f2;
    padding-top: 64px;
    .container {
        margin: 0 auto;
        min-width: 990px;
        max-width: 1200px;
    }
    .f-row {
        margin-top: 70px;
        width: 100%;
        // min-width: 990px;
        // max-width: 1100px;
        // overflow: hidden;
    }
    .f-col {
        margin-bottom: 80px;
    }
    .f-title {
        font-family: '黑体 Bold', '黑体 Regular', '黑体', sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 20px;
        margin: 16px;
    }
    .f-text {
        font-family: '华文细黑', sans-serif;
        color: #666666;
        line-height: normal;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        text-align: center;
    }
    .f-img {
        width: 96px;
        height: 96px;
    }
}

.fb32 {
    font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 32px;
    text-align: center;
}
.m26 {
    margin-top: 26px;
}

.m-title {
    font-family: '黑体 Bold', '黑体 Regular', '黑体', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 22px;
    position: relative;
    z-index: 99;
    .m-special {
        position: absolute;
        user-select: none;
        left: 6px;
        top: -70px;
        z-index: -1;
        font-family: 'Fredericka the Great Bold', 'Fredericka the Great Regular', 'Fredericka the Great', sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 120px;
        // letter-spacing: 6px;
        color: #F2F2F2;
    }
}
.m-details {
    font-family: '华文细黑', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: normal;
    color: #666666;
    max-width: 515px;
}
</style>