<template>
  <div class="input-select">
    <a-input-search :value="item.value" @change="item.value = $event.target.value;" :enter-button="btnType"
      @search="$emit('search', $event);search=true;loading=true" @focus="focus" @blur="loading && (search=false)"
      :placeholder="item.placeholder || placeholder"></a-input-search>
    <div class="select-item" v-if="search">
      <a-spin v-if="loading" size="small" style="padding: 0 12px" />
      <ul v-else-if="type === true">
        <li v-for="d in list" :class="item.id === d.id && 'li-check'" :key="d.id" @click="change(d)">
          {{ `${d.nickName}，${d.regMobile}，${d.userName}`  }}</li>
      </ul>
      <ul v-else-if="type === false">
        <li v-for="d in list" :class="item.id === d.id && 'li-check'" :key="d.id" @click="change(d)">
          {{d.commercialName || d.branchName}}
        </li>
      </ul>
      <ul v-else>
        <li v-for="d in list" :class="item[type[0]] === d[type[0]] && 'li-check'" :title="d[type[1]]" :key="d[type[0]]" @click="manualChange(d)">
          {{d[type[1]]}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default() { return [] }
    },
    type: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Boolean | Array,
      default: true
    },
    btnType: {
      type: Boolean,
      default: true
    },
    placeholder:{
       type:String,
       default:'请输入用户名、姓名或手机号模糊匹配'
    },
    focu:{
      type:Boolean,
      default:true
    }
  },
  watch: {
    list(val) {
      if (val) {
        this.loading = false
      }
    }
  },
  data() {
    return {
      item: {
        id: undefined,
        value: '',
        placeholder: ''
      },
      search: false,
      loading: false
    }
  },
  methods: {
    restart() {
      this.item = {
        id: undefined,
        value: '',
        placeholder: ''
      }
      this.search = false,
      this.loading = false
    },
    change(d) {
      this.item.id = d.id
      this.item.value = this.type ? `${d.nickName}，${d.regMobile}，${d.userName}` : d.commercialName
      this.search = false
      this.$emit('select', d)
    },
    manualChange(d) {
      this.item.id = d[this.type[0]]
      this.item.value = d[this.type[1]]
      this.search = false
      this.$emit('select', d)
    },
    focus() {
      if (!this.focu) return  
      if (this.item.value==''&&this.list.length==0) {  
      this.$emit('search','')
      this.search=true
      this.loading = true
       return
      }
      if (!this.search && this.item.id) {
        this.item.placeholder = this.item.value
        this.item.value = ''
        this.search = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
.input-select {
  position: relative;
  height: 32px;
  .select-item {
    background: #fff;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 99;
    max-height: 250px;
    margin-bottom: 0;
    padding: 4px 0;
    overflow: auto;
    list-style: none;
    outline: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    li {
      padding: 5px 12px;
      overflow: hidden;
      font-size: 14px;
      line-height: 22px;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      transition: background 0.3s ease;
      text-align: left;
      &:hover {
        background: #e2e8da;
      }
    }
    .li-check {
      background: #e2e8da;
    }
  }
}
</style>