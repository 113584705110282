<template>
  <div class="cascader">
      <ul class="cascader-content">
          <li class="cascader-item" :class="{'check-item':check === index,'no_child':!item.children.length}" v-for="(item,index) in list" :key="index" @click="changePrimary(index)">
              {{item.catValue}}
              <a-icon type="double-right" />
          </li>
      </ul>
      <ul class="cascader-content">
          <li class="cascader-item" :class="checkChildren === index && 'check-item'" v-for="(item,index) in reclassify" :key="index" @click="change(index)">
              {{item.catValue}}
              <a-icon type="double-right" />
          </li>
      </ul>
  </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            default() { return [] }
        },
        term: {
            type: String,
            default: ''
        },
        stairnId: {
            type: Number,
            default: null
        },
        accessId: {
            type: Number,
            default: null
        },
    },
    watch: {
        // 过滤返回查找的list
        term() {
            // this.checkChildren = null
            let list = []
            this.list.forEach((item,index) => {
                item.children.forEach(i => (i.fIndex = index))
                list = list.concat(item.children)
            })
            console.log(list);
            this.$emit('filterList', list.filter((item) => { return item.catValue.indexOf(this.term) !== -1}).splice(0, 8))
        },
        // 二级标签选中
        stairnId(val) {
            if (val) {
                this.check = this.list.findIndex(item => item.id == val)
            }
        },
        accessId(val) {
            if (val) {
                this.$nextTick(() => {
                    const data = this.list[this.check].children.findIndex(item => item.id == val)
                    this.checkChildren = data !== -1 ? data : null
                })
            }
        },
    },
    computed: {
        reclassify() {
            const list = (this.list[this.check || 0] || {}).children
            return list

            // if(this.term) {
            //     return list.filter(item => item.catValue.indexOf(this.term) !== -1)
            // } else {
            //     return list
            // }
        }
    },
    data() {
        return {
            check: null,
            checkChildren: null,
            // 假数据
            classify: [
                {catValue: '废塑料', children: [{catValue: '高压聚乙烯'},{catValue: '低压聚乙烯'}]},
                {catValue: '废金属', children: [{catValue: '铁'}]},
                {catValue: '废纸类', children: [{catValue: '活字印刷'}]},
            ],
        }
    },
    methods: {
        stairnIdChange(val) {
            if (val) {
                this.check = val.fIndex || 0
                const list = (this.list[this.check] || {}).children
                console.log(list,val);
                this.checkChildren = list.findIndex(item => item.id == val.id)
                this.change(this.checkChildren)
            }
        },
        changePrimary(index) {
            if(!this.list[index].children.length){
                return
            }
            this.check = index
            this.checkChildren=null
            let isChange = Object.assign({}, this.list[index])
            delete isChange.children
            this.$emit('changeSwitch',isChange)
        },
        change(index) {
            if (!this.check) this.check = 0
            this.checkChildren = index
            let primary = Object.assign({}, this.list[this.check])
            delete primary.children
            let re = this.list[this.check].children[this.checkChildren]
            this.$emit('change', [primary, re], this.list[this.check || 0], index)
        }
    }
    
}
</script>

<style lang="less" scoped>
.cascader {
    width: 100%;
    display: flex;
    justify-content: center;
    .cascader-content {
        overflow-y: auto;
        padding: 12px;
        border: 1px solid #d8d8d8;
        width: 270px;
        height: 300px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 30px;
        color: rgb(0 0 0 / 30%);
        .cascader-item {
            position: relative;
            padding: 0 6px;
            height: 30px;
            cursor: pointer;
            // transition: all .3s;
            i {
                position: absolute;
                right: 5px;
                line-height: 30px;
                opacity: 0;
                font-size: 12px;
            }
        }
        .check-item {
            background: #9fd2f6;
            border: 1px solid #40a5ed;
            color: #005b9d;
            border-radius: 2px;
            i {
                opacity: 1;
            }
        }
        .no_child{
            color: #e5e5e5;
            cursor: default;
        }
        &:last-child {
            margin-left: 20px;
        }
    }
}
</style>