<template>
  <div class="order-details">
      <a-breadcrumb separator=">" class="breadcrumb">
        <a-breadcrumb-item>
          <a @click.prevent="$router.push('/index')">首页</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click.prevent="$route.meta.isReset = true;$router.push(`/seller-content/order-manage?entityId=${checkEntity.entityId}`)">订单管理</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          订单详情
        </a-breadcrumb-item>
      </a-breadcrumb>
      <div class="order-header">
        <div class="l-flex-between">
          <p class="header">
            <a @click="$route.meta.isReset = false;$router.back()" class="back">&lt;返回</a>
            订单：{{$route.query.id}}
          </p>
          <!-- <a-rate :value="3" /> -->
          <p class="start">
            <a-popover placement="bottom" v-if="riskCR_datas.riskControlStatus=='INCOMPLETE'">
              <template slot="content">
                <a-space direction="vertical">
                  <a-badge color="#f50" v-if="riskCR_datas.contractPro!='100%'" text="合同流：合同未完成签署" />
                  <a-badge color="#f50" v-if="riskCR_datas.netPaymentPro!='100%'" text="资金流：货款未支付完成" />
                  <a-badge color="#f50" v-if="!riskCR_datas.uploadedBankReceipt" text="资金流：银行回单未完成上传" />
                  <a-badge color="#f50" v-if="riskCR_datas.logisticsPro!='100%'" text="货流：运输完成度未达100%" />
                  <a-badge color="#f50" v-if="!riskCR_datas.uploadedLogImage" text="货流：必传照片不完善" />
                  <a-badge color="#f50" v-if="riskCR_datas.invoiceStatus!='INVOICED'" text="票流：开票未完成" />
                  <a-badge color="#f50" v-if="!riskCR_datas.costDeclared" text="成本申报：卖家未申报成本" />
                </a-space>

              </template>
              <template slot="title">
                <div class="title-space">
                  <span><a-icon type="exclamation-circle" theme="filled" style="color:#fa8c16;font-size:14px"/> 待完善信息</span>
                  <a><a-icon type="sync" :spin="isSpin" style="color:#60a830;font-size:16px" @click="getOrdersRiskCR()"/></a>
                </div>
              </template>
              <a-space style="cursor:pointer;">
                <a-icon type="exclamation-circle" style="color:red;font-size:20px"/>订单五流未完善，点此查看详情
              </a-space>
            </a-popover>
            <a-popconfirm  cancelText="取消" okText="确定" title="是否下载五流风控要求?" @confirm="downLoad()">
              <a-button type="primary" icon="download" style="margin-left:20px">
                五流风控要求
              </a-button>
            </a-popconfirm>
          </p>
        </div>
        <a-tabs>
          <a-tab-pane key="1" tab="信息流">
            <Details />
          </a-tab-pane>
          <a-tab-pane key="5" tab="合同流" class="content">
            <a-button class="contract-offline" type="primary" @click="contractOffline" >线下合同</a-button>
            <Contract ref="contract"/>
          </a-tab-pane>
          <a-tab-pane key="2" tab="资金流">
            <Pay />
          </a-tab-pane>
          <a-tab-pane key="3" tab="货流">
            <Logistics />
          </a-tab-pane>
          <a-tab-pane key="4" tab="票流">
            <Invoice />
          </a-tab-pane>
          <a-tab-pane key="6" tab="商品溯源">
            <CostAmout />
          </a-tab-pane>
        </a-tabs>
      </div>
  </div>
</template>

<script>
import Details from './components/Details.vue'
import Pay from './components/Pay.vue'
import Logistics from './components/Logistics.vue'
import Invoice from './components/Invoice.vue'
import Contract from './components/Contract.vue'
import CostAmout from './components/CostAmout.vue'
import cookie from '@/utils/js-cookie.js'
import { contractOffline, ordersRiskCR } from '@/api/order'

export default {
  components: {
    Details,
    Pay,
    Logistics,
    Invoice,
    Contract,
    CostAmout
  },
  data () {
    return {
      checkEntity: cookie.get('checkEntity') || {},
      fileList: [],
      contractName: '',
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      token: cookie.get('token'),
      isSpin:false,
      riskCR_datas:{
        riskControlStatus: 'COMPLETE', //INCOMPLETE--未完善，COMPLETE--完善
        contractPro: '100%',
        logisticsPro: '100%',
        netPaymentPro: '100%',
        invoiceStatus:'INVOICED',
        costDeclared: true,
        uploadedBankReceipt: true,
        uploadedLogImage: true
      },
    }
  },
  mounted(){
    this.getOrdersRiskCR()
  },
  methods: {
    downLoad() {
      window.location.href = `${process.env.VUE_APP_API_BASE_URL}/orders/riskCR/download/standard`
    },
    getOrdersRiskCR(){
      if(this.isSpin){
        return
      }
      this.isSpin = true
      ordersRiskCR({orderId:this.$route.query.id}).then(res=>{
        setTimeout(() => {
          if (res.code === 200) {
            this.riskCR_datas = res.data

          } else {
            this.$message.error(res.message)
          }
          this.isSpin = false
        },500)
        
      })
    },
    uploadChange ({ fileList }) {
      console.log(fileList)
      if (fileList[0].type.indexOf('image') === -1 && fileList[0].type.indexOf('pdf') === -1 && fileList[0].type.indexOf('vnd.ms-excel') === -1 && fileList[0].type.indexOf('vnd.openxmlformats-officedocument.spreadsheetml.sheet') === -1 && fileList[0].type.indexOf('msword') === -1 && fileList[0].type.indexOf('vnd.openxmlformats-officedocument.wordprocessingml.document') === -1) {
        this.$message.error('请上传正确的格式')
        this.fileList = []
        return
      }
      if (fileList.length) {
        this.fileList = [fileList[fileList.length - 1]]
        console.log(this.fileList)
      } else {
        this.fileList = []
      }
    },
    contractOffline () {
      const that = this
      this.$confirm({
        icon: () => '',
        centered: true,
        width: 520,
        maskClosable: true,
        autoFocusButton: null,
        cancelText: '取消',
        okText: '确定',
        content: () => <div> <a-row class={'offline'}>
          <a-col span={24} class={'title'}>线下合同</a-col>
          <a-col span={4}>文件上传：</a-col>
          <a-col span={20}>
            <a-upload file-list={that.fileList} accept="image/*,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" action={that.baseUrl + '/qiniu/upload'} headers={{ Authorization: that.token }} onchange={that.uploadChange}>
              <a-button> <a-icon type="upload" /> 上传</a-button> <span style="color: red">仅允许上传图片、pdf、doc、docx等类型文件</span>
            </a-upload>
          </a-col>
          <a-col span={4}>文件名称：</a-col>
          <a-col span={20}>
            {<a-input v-model={that.contractName} placeholder="请输入"/>}
          </a-col>
        </a-row>
        </div>,
        onOk () {
          return new Promise((resolve, reject) => {
            let filePath; let originalFileName; let fileSuffix
            if (that.fileList.length) {
              filePath = `https://www.taxoss.zz91.cn/${that.fileList[0].response.data}`
              originalFileName = that.fileList[0].name
              const l = originalFileName.split('.')
              fileSuffix = l[l.length - 1]
            }
            if (!filePath) {
              that.$message.error('请先上传文件')
              reject(new Error())
              return
            }
            if (!that.contractName) {
              that.$message.error('请输入文件名称')
              reject(new Error())
              return
            }
            contractOffline({ fileName: that.contractName, filePath, orderId: that.$route.query.id, originalFileName, fileSuffix }).then(res => {
              if (res.code === 200) {
                that.contractName = ''
                that.fileList = []
                that.$message.success(res.message)
                that.$refs.contract.init()
                resolve()
              } else {
                that.$message.error(res.message)
                reject(new Error())
              }
            })
          })
        },
        onCancel () {
          that.contractName = ''
          that.fileList = []
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.order-header {
  // padding-top: 18px;
  max-width: 1440px;
  min-width: 990px;
  @media screen and (max-width: 1440px){
      padding: 0 15px;
  }
  margin: 0 auto;
  // background: #fff;
  .l-flex-between {
    padding: 20px 20px;
    background: #fff;
  }
  .header {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 28px;
    font-weight: 700;
  }
}
/deep/ .ant-tabs-nav-scroll {
    padding: 0 20px;
    background: #fff;
}
.breadcrumb {
  @media screen and (max-width: 1440px){
      padding: 12px 15px;
  }
}
.content {
  position: relative;
  .contract-offline {
    position: absolute;
    right: 20px;
    top: -50px;
  }
}
.offline {
  .title {
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    margin-bottom: 24px;
  }
  /deep/ .ant-col {
    margin-bottom: 12px;
    line-height: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.back {
  font-size: 14px;
  margin-right: 8px;
}

.title-space{
  display: flex;
  justify-content:space-between;
  align-items: center;
}
</style>