<template>
    <div class="no-result">
        <div class="result-page">
            <div>
                <p><a-icon type="inbox" class="icon" /></p>
                <p><span>抱歉，没有搜索到 <span class="error-font">{{this.$route.query.product}}</span> 相关的商品</span></p>
                <p><span>请检查您的输入是否有误</span></p>
                <!-- <div class="recommend">
                    <p>您还可以尝试以下搜索：</p>
                    <p>
                        <span class="error-font" v-for="(item, index) in list" :key="index"> {{item}}<span class="font-space" v-if="index + 1 !== list.length"> |</span>
                        </span>
                    </p>
                </div> -->
            </div>
        </div>

        <a-card title="猜你喜欢" class="result-recommend" v-show="false">
            <div class="recommend">
                <div class="left" @click.stop="defineX && (defineX += translateX)">
                    <a-icon type="left"/>
                </div>
                <div class="right" @click.stop="defineX -= translateX">
                    <a-icon type="right"/>
                </div>

                <div class="recommend-list" ref="recommend" :style="{transform: `translateX(${defineX}px)`}">
                    <div class="recommend-chunk" v-for="(item,index) in 12" :key="index">
                        <div>
                            <div class="img"><a-icon type="shopping" /></div>
                            <p>LDPE颗粒</p>
                            <p><span class="error-font">¥2400.00</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </a-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            defineX: 0,
            translateX: 0,
            list: ['全部','低压聚乙烯','尼龙','聚丙烯','聚氯乙烯','聚碳酸酯','聚酯','高压聚乙烯','其他']
        }
    },
    mounted() {
        this.translateX = (this.$refs.recommend && this.$refs.recommend.clientWidth) || 0
        console.log('translateX', this.translateX)
    },
}
</script>

<style lang="less" scoped>
    .no-result {
        margin-top: 20px;
        .error-font {
            color: @error;
        }
        .result-page {
            margin: 0 auto;
            margin-bottom: 20px;
            width: 1440px;
            background: #fff;
            height: 600px;
            border-width: 1px;
            border-style: solid;
            border-color: @border;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #999;
            .icon {
                font-size: 96px;
                margin-bottom: 12px;
            }
            .recommend {
                margin-top: 48px;
                .font-space {
                    color: #999;
                }
            }
            p {
                text-align: center;
                font-size: 16px;
                margin-bottom: 16px;
            }
        }
        .result-recommend {
            margin-bottom: 40px;
            /deep/ .ant-card-head {
                background: #f9f9f9;
                color: #999999;
            }
            .recommend {
                position: relative;
                height: 300px;
                padding: 0 18px;
                overflow: hidden;
                .recommend-list {
                    transition: all .3s;
                    display: flex;
                    .recommend-chunk {
                        margin: 0 2%;
                        min-width: 21%;
                        height: 300px;
                        border: 1px solid #f2f2f2;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .img {
                            font-size: 42px;
                            text-align: center;
                        }
                        p {
                            margin-top: 16px;
                            font-size: 14px;
                            text-align: center;
                        }
                    }
                }
                i {
                    cursor: pointer;
                }
                .left {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    border: 1px solid #e4e4e4;
                    border-radius: 50%;
                    text-align: center;
                    cursor: pointer;
                    font-size: 12px;
                    z-index: 99;
                    &:hover {
                        background: #f2f2f2;
                    }
                }
                .right {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    border: 1px solid #e4e4e4;
                    border-radius: 50%;
                    text-align: center;
                    cursor: pointer;
                    font-size: 12px;
                    z-index: 99;
                    &:hover {
                        background: #f2f2f2;
                    }
                }
            }
        }
    }
</style>