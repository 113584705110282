<template>
  <div>
    <a-card>
      <a-space slot="title" :size="24">
        <span>车辆记录</span>
        <font color="red" size="2">*请确保货流的起止时间与实际运输时间段一致，否则会导致轨迹线路与实际不符</font>
      </a-space>
      <a-row type="flex" justify="space-between" style="margin-bottom: 12px">
        <a-col>
          <a-popconfirm title="确认删除?" @confirm="delLogistics(selectedRowKeys)">
            <a-button type="primary">批量删除</a-button>
          </a-popconfirm>
        </a-col>
        <a-col>
          <a-button type="primary" @click="add">添加车辆</a-button>
        </a-col>
      </a-row>
      <template slot="extra">
        <a-space>
          <span>物流完成度：</span><span class="percentage">{{scheduleS.logisticsPro}}</span><span>剩余{{scheduleS.subtract}}{{scheduleS.unit | unit}}</span>
          <MiniProgress :target="80" style="width: 220px;margin-right:12px" :percentage="percentage" height="12px"/>
          <span class="weight">{{scheduleS.quantity}}{{scheduleS.unit | unit}}</span>
        </a-space>
      </template>
      <a-table :data-source="data" size="middle" :columns="logisticsColumns" rowKey="id" :loading="loading" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :customRow="Rowclick" :row-class-name="rowClassName"
      @change="handleTableChange"
      :pagination="{
          size: 'big',
          total: form.total,
          current: form.pageNum,
          pageSize: form.pageSize,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['5', '10', '20', '30', '40'],
          showTotal: total => `共有 (${form.total}) 条记录 第 ${form.pageNum} / ${totalPage} 页`}">

        <template
          slot="plateNumber"
          slot-scope="text, record"
        >
          <div>
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="e => handleChange(e.target.value, record.id, 'plateNumber')"
            />
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>

        <template
          slot="startAddress"
          slot-scope="text, record"
        >
          <div>
            <a-popover
              trigger="hover"
              :visible="!!text&&record.isFocusStart"
              v-if="record.editable"
            >
              <div slot="content">
                {{ text }}
              </div>
              <a-auto-complete
                class="global-search"
                style="margin: -5px 0;min-width: 100px;"
                placeholder="请输入地址"
                option-label-prop="title"
                @select="(value)=>onSelect(record,'startAddress',value)"
                @search="value=>handleSearch(record,value,'startAddress')"
                :value="text"
                :dropdown-match-select-width="false"
                v-if="record.editable"
                @blur="record.isFocusStart = false"
                @focus="record.isFocusStart = true"
              >
                <template slot="dataSource">
                  <a-select-option v-for="item in dataSourceStart" :key="item.id" :title="`${item.provinceName} ${item.cityName} ${item.areaName} ${item.detailAddress}`">
                      <a>{{ item.name }}</a>{{ `：${item.provinceName} ${item.cityName} ${item.areaName} ${item.detailAddress}` }}
                  </a-select-option>
                </template>
                <a-input>
                  <a-button
                    slot="suffix"
                    style="margin-right: -12px"
                    class="search-btn"
                    type="primary"
                    @click="getLogisticsPoiSearch(record,'startAddress')"
                  >
                    <a-icon type="search" />
                  </a-button>
                </a-input>
              </a-auto-complete>
            </a-popover>
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>

        <template
          slot="endAddress"
          slot-scope="text, record"
        >
          <div>
            <a-popover
              trigger="hover"
              :visible="!!text&&record.isFocusEnd"
              v-if="record.editable"
            >
              <div slot="content">
                {{ text }}
              </div>
              <a-auto-complete
                class="global-search"
                style="margin: -5px 0;min-width: 100px;"
                placeholder="请输入地址"
                option-label-prop="title"
                @select="(value)=>onSelect(record,'endAddress',value)"
                @search="value=>handleSearch(record,value,'endAddress')"
                :value="text"
                :dropdown-match-select-width="false"
                @blur="record.isFocusEnd = false"
                @focus="record.isFocusEnd = true"
              >
                <template slot="dataSource">
                  <a-select-option v-for="item in dataSourceEnd" :key="item.id" :title="`${item.provinceName} ${item.cityName} ${item.areaName} ${item.detailAddress}`">
                    <a>{{ item.name }}</a>{{ `：${item.provinceName} ${item.cityName} ${item.areaName} ${item.detailAddress}` }}
                  </a-select-option>
                </template>
                <a-input>
                  <a-button
                    slot="suffix"
                    style="margin-right: -12px"
                    class="search-btn"
                    type="primary"
                    @click="getLogisticsPoiSearch(record,'endAddress')"
                  >
                    <a-icon type="search" />
                  </a-button>
                </a-input>
              </a-auto-complete>
            </a-popover>
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>

        <template slot="dataSources" slot-scope="text">
          {{ text==="APP"?'小程序':'手动' }}
        </template>

        <template slot="logisticsImgPerfectStatus" slot-scope="text">
          <a-icon type="check-circle" theme="filled" v-if="text==='FULL'" :style="{color:'#D9001B'}"/>
          <a-icon type="close-circle" theme="filled" v-else/>
        </template>

        <template slot="quantity" slot-scope="text, record">
            <a-input-number
              v-if="record.editable && record.id !== 0"
              :style="{marginTop:'-5px',marginBottom:'-5px',borderColor:record.stop?'red':'#d9d9d9'}"
              :value="record.quantity"
              :min="0"
              :precision="scheduleS.unit === 'S' || scheduleS.unit === 'C' ? 0 : 4"
              @change="e => handleChange(e, record.id, 'quantity')"
              @blur="e=>onNumberBlur(e,'updata',record.id,record)"
            />
            <a-input-number
              v-else-if="record.id === 0"
              :style="{marginTop:'-5px',marginBottom:'-5px',borderColor:record.stop?'red':'#d9d9d9'}"
              :value="record.quantity"
              :min="0"
              :precision="scheduleS.unit === 'S' || scheduleS.unit === 'C' ? 0 : 4"
              @change="e => handleChange(e, record.id, 'quantity')"
              @blur="e=>onNumberBlur(e,'add',record.id,record)"
            />
            <template v-else>
              {{ text }}
            </template>
        </template>
        <template slot="action" slot-scope="text, record">
          <span v-if="record.editable">
            <a-space>
              <a @click.stop="() => save(record)">确定</a>
              <a type="close" @click.stop="() => cancel(record.id)">取消</a>
            </a-space>
          </span>
          <span v-else>
            <a-space>
              <a v-if="record.enableEdit" @click.stop="toggle(record.id)">编辑</a>
              <a-popconfirm
                cancelText="取消"
                okText="确定"
                title="确定删除?"
                @confirm="() => delLogistics(record.id)"
              >
                <a @click.stop>删除</a>
              </a-popconfirm>
            </a-space>
            
          </span>
        </template>
        <template slot="transType" slot-scope="text,record">
          <a-select v-if="record.editable" :value="record.transType" style="width: 80px" placeholder="请选择" @change="record.transType = $event">
            <a-select-option v-for="(item) in transList" :key="item.key">
              {{ item.label }}
            </a-select-option>
          </a-select>
          <span v-else>{{ text | transType }}</span>
        </template>
        <template slot="endTime" slot-scope="text,record">
          <a-date-picker
            v-if="record.editable"
            format="YYYY-MM-DD HH:mm:ss"
            :value="record.endTime"
            placeholder="请选择到达时间"
            show-time
            :disabled-date="disabledStartDate"
            @change="pickerChange($event, record)"
          />
          <span v-else>{{ text }}</span>
        </template>
        <template slot="distance" slot-scope="text, record">
          <a-date-picker
            v-if="record.editable"
            format="YYYY-MM-DD HH:mm:ss"
            :value="record.startTime"
            placeholder="出发时间"
            show-time
            :disabled-date="disabledStartDate"
            @change="pickerChangeStartTime($event, record)"
          />
          <span v-else :style="{color: record.distance >= 500 && 'red'}">{{ record.distance }}</span>
        </template>
      </a-table>
    </a-card>

    <a-card v-show="orderLogisticsId">
      <a-space slot="title" :size="24">
        <span>货运信息</span>
        <!-- :disabled="loadingBtn" -->
        <a-button type="primary" :loading="loadingBatch" @click="batchUpload">批量上传图片</a-button>
        <font color="red" size="2">*单张图片最大不超过15M，批量图片之和不超过20M</font>
      </a-space>
      <a-spin :spinning="freightLoading">
        <a-row type="flex" justify="space-between">
          <!-- <a-col><a-button type="primary">批量上传照片</a-button></a-col> -->
          <!-- <a-col><a-input-search placeholder="请输入关键字">批量上传照片</a-input-search></a-col> -->
        </a-row>

        <a-row>
          <a-col align="center" v-if="detailData" :span="14" :offset="5" >
            <a-table
            :columns="detailColumns"
            rowKey="dataSources"
            size="middle"
            :data-source="[detailData]"
            :pagination="false"
            bordered >
              <template slot="dataSources" slot-scope="text">
                <a-space v-if="text=='APP'">
                  <img src="../../../assets/images/xcx.png" alt="" style="width:20px">
                  小程序
                </a-space>
                <a-space v-else>
                  <a><a-icon type="user-add" style="font-size: 20px;"/></a> 手动
                </a-space>
              </template>
              <template slot="transportStatus" slot-scope="text,record">
                <a-space >
                  <a v-if="text==1"><a-icon type="check-circle" style="font-size: 20px;"/></a>
                  <a-icon v-if="text==0" type="close-circle" style="font-size: 20px;color:red"/>
                   {{ text==='2'?'--':record.transportMsg }}
                </a-space>
              </template>
              <template slot="imgStatus" slot-scope="text,record">
                <a-space>
                  <a v-if="text"><a-icon type="check-circle" style="font-size: 20px;"/></a>
                  <a-icon v-else type="close-circle" style="font-size: 20px;color:red"/>
                  {{ record.imgMsg }}
                </a-space>
              </template>
              <template slot="trackMsgTrack" slot-scope="text,record">
                <a-space>
                   {{ text==1? record.trackMsg:'--' }}
                </a-space>
              </template>
            </a-table>
          </a-col>
        </a-row>

        <a-divider />

        <template v-if="detailData.transType!=='TRAIN'&&detailData.transType!=='SHIP'">
          <div class="doc-row" >
            <div class="title">车辆信息</div>
            <div class="doc-context">
              <a-row>
                <a-col :span="24" v-if="!detailData.driverPhone">
                  <a-button type="primary" :loading="loadingBatch" @click="editRow(false)">添加车辆详细信息</a-button>
                </a-col>
                <a-col :span="24" align="right" v-else>
                  <a-button type="primary" :loading="loadingBatch" @click="editRow(true)">编辑车辆详细信息</a-button>
                </a-col>
              </a-row>

              <div class="doc-info" v-if="detailData.driverPhone">
                <a-space direction="vertical">
                  <p>车牌号：{{ detailData.plateNumber }}</p>
                  <p>司机：{{ detailData.driverName }}</p>
                  <p>联系电话：{{ detailData.driverPhone }}</p>
                </a-space>
                <a-space class="doc-right">
                  <div class="doc-itme" v-if="detailData.transType==='TRUCK'">
                    <div class="doc-pic"><img :src="detailData.carDrivingLicenseUrl||require('../../../assets/images/noImg.png')" alt=""></div>
                    <div class="doc-name">行驶证</div>
                  </div>
                  <div class="doc-itme" v-if="detailData.transType==='TRUCK'">
                    <div class="doc-pic"><img :src="detailData.drivingLicenseUrl||require('../../../assets/images/noImg.png')" alt=""></div>
                    <div class="doc-name">驾照</div>
                  </div>
                  <div class="doc-itme">
                    <div class="doc-pic"><img :src="detailData.carPersonPhotoUrl||require('../../../assets/images/noImg.png')" alt=""></div>
                    <div class="doc-name">人车合照</div>
                  </div>
                  <div class="doc-itme" v-if="detailData.transType==='TRICYCLE'">
                    <div class="doc-pic"><img :src="detailData.idCardFrontUrl||require('../../../assets/images/noImg.png')" alt=""></div>
                    <div class="doc-name">身份证</div>
                  </div>
                </a-space>
              </div>
            </div>
          </div>

          <a-divider style="margin-top:80px"/>
        </template>

        <a-row type="flex" :gutter="14" class="row">
          <a-col :span="6" v-for="(item,index) in detailData.imgs" :key="index">
            <div class="freight-photo">
              <img :src="item.imgPath||require('@/assets/images/noImg.png')" alt="">
               <p class="add-info l-flex-between" v-if="imgNum==item.id">
                  <a-select class="select" :value="editPhotoObj.value" @change="selectEdit" >
                    <a-select-option v-for="item in editPhotoObj.list" :key="item.label">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                  <a-space>
                    <a-icon type="close" @click.stop="clear2();getLogisticsDetail()"></a-icon>
                    <a-icon type="check" @click.stop="item.imgName=editPhotoObj.value;editFreight(item.imgPath)"></a-icon> 
                  </a-space>
                </p>
              <p class="info" v-else>{{item.imgName}} <span @click="editImgInfo(item)"><a-icon type="edit"  /></span></p>
              <span class="close" @click.stop="delImg(item.id)"><a-icon type="close"></a-icon></span>
              <div class="mask" @click="show(item.imgPath)">
                <a-space>
                  <a-icon type="eye"></a-icon>
                  <a-icon v-if="imgNum==item.id" @click.stop="selectImg()" type="edit"></a-icon>
                </a-space>
              </div>
             <p class="add-info " style="padding-top:3px" v-if="editPhotoObj.value=='自定义'&&imgNum==item.id">
                <a-input placeholder="请输入自定义标签名称" v-model="editPhotoObj.custom"></a-input>
             </p>
            </div>
          </a-col>
          <a-col :span="6">
            <div class="freight-photo drag-ref" :class="[!addPhotoObj.isShow && 'l-flex-allcenter']" @click="addPhotoObj.isShow = true;imgNum=null;">
              <template v-if="addPhotoObj.isShow">
                <img v-if="addPhotoObj.img" :src="addPhotoObj.img" alt="" @click="selectImg">
                <div v-else class="img-plus" @click="selectImg">
                  <a-icon type="loading" v-if="addPhotoObj.loading"></a-icon>
                  <a-icon type="plus" v-else></a-icon>
                </div>
                <p class="add-info l-flex-between">
                  <a-select class="select" :value="addPhotoObj.value" @change="selectChange" placeholder="请选择">
                    <a-select-option v-for="(item) in addPhotoObj.list" :key="item.label">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                  <a-space>
                    <a-icon type="close" @click.stop="clear"></a-icon>
                    <a-icon type="check" @click.stop="addFreight()"></a-icon>
                  </a-space>
                </p>
                <span class="close" @click.stop="clear"><a-icon type="close"></a-icon></span>
                <p class="add-info " style="padding-top:3px" v-if="addPhotoObj.value=='自定义'">
                <a-input placeholder="请输入自定义标签名称" v-model="addPhotoObj.custom"></a-input>
                </p>
              </template>
              <span v-else class="add-text">
                <a-icon type="plus"></a-icon>
                <p>点击/拖拽 添加</p>
              </span>
            </div>
          </a-col>
        </a-row>

        <!-- <a-row class="more">
          <a-button>加载更多…</a-button>
        </a-row> -->

        <a-row type="flex" justify="center" align="middle" class="foot">
          <p class="">{{getRecord.startAddress}}</p>
          <div class="distance">
            <span>起点</span>
            <div class="content">
              <img class="trucks" src="../../../assets/images/u236.svg" alt="">
              <img class="arrows" src="../../../assets/images/u237.svg" alt="" />
              <span class="text">{{getRecord.distance}} KM</span>
            </div>
            <span>终点</span>
          </div>
          <p>{{getRecord.endAddress}}</p>
        </a-row>
      </a-spin>
    </a-card>
    <!-- img -->
    <input v-show="false" id="addImage" ref="addImage" alt="" class="upload" type="file" name="addImage" multiple="multiple" accept="image/png,image/jpeg,image/gif,image/jpg" @change="getImgFile"/>
    <input
      v-show="false"
      ref="addBatchImage"
      alt=""
      class="upload"
      type="file"
      name="addImage"
      multiple="multiple"
      accept="image/png,image/jpeg,image/gif,image/jpg"
      @change="getBatchImgFile"/>
    <a-modal :width="800" :visible="imgVisible" :footer="null" @cancel="imgVisible = false">
      <img alt="营业执照照片" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { transType,logisticsType } from "@/utils/constArr";
import { uploadPhoto, uploadBatchPhoto } from '@/api/utils'
import drag from '@/utils/drag'
import{type } from "@/utils/constArr"
import { getLogistics,getImg,getAddrFull,addLogistics,delLogistics,addImg,delImg,schedule,edit,editImg,logisticsPoiSearch, logisticsDetail } from '@/api/order'
import MiniProgress from '@/components/MiniProgress'
import EditMachine from '@/components/editMachine'
import moment from 'moment'
// const carNumber =/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/
const logisticsColumns = [
  {
    title: '工具编号',
    dataIndex: 'plateNumber',
    width: 140,
    scopedSlots: { customRender: 'plateNumber' },
    align: 'left',
  },
  {
    title: '数量',
    dataIndex: 'quantity',
    width: 130,
    scopedSlots: { customRender: 'quantity' },
    align: 'right',
  },
  {
    title: '起点',
    dataIndex: 'startAddress',
    scopedSlots: { customRender: 'startAddress' },
    align: 'left',
  },
  {
    title: '终点',
    dataIndex: 'endAddress',
    scopedSlots: { customRender: 'endAddress' },
    align: 'left',
  },
  {
    title: '运输方式',
    dataIndex: 'transType',
    width: 100,
    scopedSlots: { customRender: 'transType' },
    align: 'left',
  },
  {
    title: '运输距离 km',
    dataIndex: 'distance',
    scopedSlots: { customRender: 'distance' },
    align: 'right',
  },
  {
    title: '来源方式',
    dataIndex: 'dataSources',
    scopedSlots: { customRender: 'dataSources' },
    align: 'left'
  },
  {
    title: '到达时间',
    dataIndex: 'endTime',
    scopedSlots: { customRender: 'endTime' },
    align: 'left',
  },
  {
    title: '照片完善度',
    dataIndex: 'logisticsImgPerfectStatus',
    scopedSlots: { customRender: 'logisticsImgPerfectStatus' },
    align: 'left'
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' }
  },
]
// 运输类型
const transList = [
  {label:'货车',key:'TRUCK'},
  {label:'三轮车',key:'TRICYCLE'},
  { label: '船运', key: 'SHIP' },
  { label: '铁路', key: 'TRAIN' }
]

// 物流图片类型list
const detailColumns = [
  {
    title: '记录方式',
    dataIndex: 'dataSources',
    width: 150,
    scopedSlots: { customRender: 'dataSources' },
    align: 'left'
  },
  {
    title: '车辆信息',
    dataIndex: 'transportStatus',
    width: 150,
    scopedSlots: { customRender: 'transportStatus' },
    align: 'left'
  },
  {
    title: '照片上传',
    dataIndex: 'imgStatus',
    width: 150,
    scopedSlots: { customRender: 'imgStatus' },
    align: 'left'
  },
  {
    title: '轨迹状态',
    dataIndex: 'trackMsgTrack',
    width: 150,
    scopedSlots: { customRender: 'trackMsgTrack' },
    align: 'left'
  }
]

// 物流图片类型list

const list = [
  { label: '车牌照' },
  { label: '过磅照' },
  { label: '磅单照' },
  { label: '出库单' },
  { label: '质检单' },
  { label: '货流照' },
  { label: '高速票/ETC' },
  { label: '运输发票' },
  { label: '自定义' }
]
export default {
  components: {
    MiniProgress
  },
  data() {
    return {
      moment,
      logisticsColumns,
      transList,
      loading: false,
      freightLoading: false,
      orderLogisticsId: '',
      data: [],
      selectedRowKeys: [],
      freightList: [],
      Selected: '',
      site: {
        delyFullAddr: '',
        addrFull: ''
      },
      distance:'',
      addPhotoObj: {
        loading: false,
        isShow: false,
        list,
        value: undefined,
        img: ''
      },
      editPhotoObj:{
        loading: false,
        list,
        value: undefined,
        custom:undefined,
        img: ''
      },
      scheduleS:{
        unit:'',//单位
        logisticsPro:undefined, //进度，
        quantity:undefined, //总数
        subtract:undefined //剩余
      },
      imgNum:null,
      imgVisible: false,
      previewImage: '',
      form: {
          pageNum: 1,
          pageSize: 5,
          total: 0
      },
      totalPage: 0,
      loadingBatch: false,
      dataSourceStart: [],
      dataSourceEnd: [],
      detailData: '',
      detailColumns
    }
  },
  filters: {
    unit:(val)=>type[val],
    status: (val) => logisticsType[val],
    transType: (val) => transType[val],
    length(val){
      if(!val) return 0
      const obj={
        plateNumber:7
      }
      return obj[val] || 0
    }
  },
  computed: {
    getRecord() {
      if (this.orderLogisticsId) {
        return this.data.find(item => item.id == this.orderLogisticsId) || {}
      } else{
        return {}
      }
    },
    percentage () {
      if (this.scheduleS.logisticsPro) {
        const data = this.scheduleS.logisticsPro.split('%')
        return data[0] ? Number(data[0]) : 0
      } else {
        return 0
      }
    }
  },

  mounted() {
    this.init()
    drag('drag-ref', true, (e) => {
      this.addPhotoObj.isShow = true
      this.addPhotoObj.loading = true
      uploadPhoto(Array.from(e)[0], (url) => {
        this.addPhotoObj.loading = false
        this.addPhotoObj.img = url
      })
    })
  },
  methods: {
    // isEdit是否是编辑
    editRow (isEdit) {
      const { detailData } = this
      const that = this
      this.$dialog(EditMachine, {
        record: { ...detailData, isEdit, logisticsId: this.orderLogisticsId },
        on: {
          ok () {
            that.getLogistics()
            that.getLogisticsDetail()
          }
        }
      },
      {
        title: `${detailData.plateNumber}（${transType[detailData.transType]}）`,
        width: 600,
        centered: true,
        footer: '',
        wrapClassName: 'custom-dialog'
      })
    },
    handleSearch (record, value, col) {
      record[col] = value
    },
    onSelect (record, col, value) {
      console.log(record)
      console.log(col)
      console.log(value)
      if (col === 'startAddress') {
        const selectValue = this.dataSourceStart.find(item => item.id === value)
        console.log(`${selectValue.provinceName} ${selectValue.cityName} ${selectValue.areaName} ${selectValue.detailAddress}`)
        record[col] = `${selectValue.provinceName} ${selectValue.cityName} ${selectValue.areaName} ${selectValue.detailAddress}`
      } else {
        const selectValue = this.dataSourceEnd.find(item => item.id === value)
        record[col] = `${selectValue.provinceName} ${selectValue.cityName} ${selectValue.areaName} ${selectValue.detailAddress}`
      }
      
    },
    async getLogisticsPoiSearch (record, col) {
      const req = {
        search: record[col]
      }
      if (record.transType === 'SHIP') {
        req.type = 2
      } else if (record.transType === 'TRAIN') {
        req.type = 1
      }
      const res = await logisticsPoiSearch(req)
      if (res.code === 200) {
        if (col === 'startAddress') {
          this.dataSourceStart = res.data
        } else {
          this.dataSourceEnd = res.data
        }
      } else {
        this.$message.error(res.message)
      }
    },
    onNumberBlur(event,type,id,record){
      console.log(event)
      let { scheduleS } = this
      let currentNum = + event.target.value; //输入值
      let maxNum; //最大值
      if(type=="add"){ //新增
        maxNum = scheduleS.unit === 'S' || scheduleS.unit === 'C' ? scheduleS.subtract : scheduleS.subtract + (scheduleS.quantity * 0.05)
      }else{ //编辑
        maxNum = scheduleS.unit === 'S' || scheduleS.unit === 'C' ? record._originalData.quantity + scheduleS.subtract : record._originalData.quantity + (scheduleS.quantity * 0.05) + scheduleS.subtract
      }
      const newData = [...this.data]
      const target = newData.find(item => id === item.id)
      if(currentNum>maxNum){
        this.$message.error(`您输入的数量大于最高值${maxNum}`) 
        if (target) {
          target.stop = true
          this.data = newData
        }
      }else{
        if (target) {
          target.stop = false
          this.data = newData
        }
      }

      
      
    },
    // 初始化信息
    init() {
      const id = this.$route.query.id
      getAddrFull(id).then(res => {
        if (res.code === 200) {
          this.site.addrFull = res.data.startAddrFull
          this.site.delyFullAddr = res.data.delyFullAddr
        }
        
      })
      this.getLogistics(true)
      this.getSchedule()
    },
    // 获取车辆记录
    getLogistics(isFirst) {
      this.loading = true
      getLogistics({ orderId: this.$route.query.id, ...this.form }).then(res => {
        this.loading = false
        this.Selected = false
        const org = {plateNumber: '', quantity: null,endTime: null, startTime:null, transType: '',startAddress: '',endAddress: '',editable: false}
        this.data = res.data.list.map(item => { return { ...org, ...item, isFocusStart: false, isFocusEnd: false } })
        this.form.total = res.data.total
        this.totalPage = res.data.totalPage
        if (isFirst) {
          this.orderLogisticsId = this.data[0] ? this.data[0].id : ''
          // this.getImg()
          this.getLogisticsDetail()
        }
      })
    },
    getLogisticsDetail () {
      logisticsDetail({ orderLogisticsId: this.orderLogisticsId }).then(res => {
        this.freightLoading = false
        this.clear2()
        if (res.code === 200) {
          const data = res.data
          data.imgs = data.imgs.filter(item=>(item.required || item.imgPath)).map((item, index) => {
            item.id = item.id || `add_${index}`
            return item
          })
          this.detailData = data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleTableChange (e) {
        this.form.pageNum = e.current
        this.form.pageSize = e.pageSize
        this.getLogistics(true)
    },
    // 获取图片list
    getImg() {
      this.clear2()
      getImg(this.orderLogisticsId).then(res => {
        this.clear2()
        this.freightLoading = false
        this.freightList = res.data
      })
    },
    // 获取物流进度
    getSchedule(){
      schedule({orderId:this.$route.query.id}).then(res=>{
        this.scheduleS={...this.scheduleS,...res.data}
        this.logisticsColumns[1].title = `数量${this.scheduleS.unit && '（' + type[this.scheduleS.unit] + '）'}`
      })
    },
    // 删除图片
    delImg(id) {
      if (('' + id).indexOf('add_') > -1) {
        this.$message.error('必传照片不能删除！')
        return
      }
      delImg({delList:[id]}).then(res => {
        if (res.code === 200) {
          this.getLogistics()
          this.getLogisticsDetail()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 修改图片信息
    editImgInfo (item) {
      this.clear2()
      this.imgNum = item.id
      if (this.editPhotoObj.list.find(i => i.label === item.imgName)) {
        this.editPhotoObj.value = item.imgName
      } else {
        this.editPhotoObj.value = '自定义'
        this.editPhotoObj.custom = item.imgName
      }
    },
    // 选择img类型
    selectChange(value) {
      this.addPhotoObj.value = value
    },
    selectEdit(value) {
      this.editPhotoObj.value = value  
    },
    pickerChange(m,record) {
      if (m) {
        record.endTime = m.format('YYYY-MM-DD HH:mm:ss')
      } else {
        record.endTime = null
      }
    },
    pickerChangeStartTime (m, record) {
      console.log(m);
      if (m) {
        record.startTime = m.format('YYYY-MM-DD HH:mm:ss')
      } else {
        record.startTime = null
      }
    },
    disabledStartDate(current) {
      return current && current > moment().endOf('day')
    },
    addFreight() {
      if(!this.addPhotoObj.img) {
        this.$message.error('请上传图片')
        return
      } else if(!this.addPhotoObj.value) {
        this.$message.error('请选择上传照片类型')
        return
      } else if (this.addPhotoObj.value === '自定义' && !this.addPhotoObj.custom) {
        this.$message.error('请输入自定义标签名称')
        return
      }
      // 添加图片。。。。。。
      const config = {
        orderLogisticsId: this.orderLogisticsId,
        imgDtoList: [{imgName: this.addPhotoObj.value=="自定义"?this.addPhotoObj.custom:this.addPhotoObj.value, imgPath: this.addPhotoObj.img}]
      }
      addImg(config).then(res => {
        if (res.code === 200) {
          this.getLogistics()
          this.getLogisticsDetail()
          this.clear()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 重置图片
    clear() {
      this.addPhotoObj.isShow = false
      this.addPhotoObj.value = undefined
      this.addPhotoObj.custom = undefined
      this.addPhotoObj.img=''
      this.getLogisticsDetail()
    },
    // 取消编辑
    clear2(){
      this.imgNum=null
      this.editPhotoObj.value = undefined
      this.editPhotoObj.custom = undefined
      this.editPhotoObj.img=''
    },
    // 车辆记录input框变动
    handleChange(value, id, column) {
      const newData = [...this.data]
      const target = newData.find(item => id === item.id)
      if (target) {
        target[column] = value
        this.data = newData
      }
    },
    // 添加车辆记录
    add() {
      if (this.data.find(item => item.id === 0)) return
      const obj = {id:0, plateNumber: '',isFocusStart: false, isFocusEnd: false,  quantity: null,endTime: null, startTime:null, transType: 'TRUCK',startAddress: this.site.addrFull,endAddress: this.site.delyFullAddr,editable: true,}
      this.Selected = true
      this.data.unshift(obj)
    },
    // 保存车辆记录
    save(record) {
      let time = record.startTime
      if (!(record.quantity>0)) {
        this.$message.error("请输入数量")  
        return
      }else if(record.stop){
        this.$message.error("数量超出最大值")  
        return
      }else if (!record.startAddress) {
        this.$message.error("请输入发货地址")
        return
      }else if (!record.endAddress) {
        this.$message.error("请输入收货地址")
        return
      }else if (!record.transType) {
        this.$message.error("请选择运输类型") 
        return
      }else if (!record.endTime) {
        this.$message.error("请选择到达时间")
        return
      }
      if (record.startTime && moment(time).valueOf() >= moment(record.endTime).valueOf()) {
        this.$message.error('开始时间不允许晚于或等于到达时间')
        return
      }
      time = record.startTime || moment(record.endTime).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.loading=true
      if (record.id === 0) {
        addLogistics({...record, orderId: this.$route.query.id}).then(res => {
          this.loading=false
          if (res.code === 200) {
            this.getLogistics()
            this.getSchedule()
            this.Selected = false
          } else {
            this.$message.error(res.message)
          }
        }).catch(r=>{
          this.loading=false
          console.log(r);
        })
      } else {
        edit({...record, orderId: this.$route.query.id,startTime: record.startTime || undefined,plateNumber: record.transType === 'TRUCK' || record.transType === 'SHIP' ? (record.plateNumber || undefined) : (record.plateNumber || '')}).then(res => {
          this.loading=false
          this.addPhotoObj.isShow=false
          if (res.code === 200) {
            this.getLogistics()
            this.getSchedule()
            this.Selected = false
          } else {
            this.$message.error(res.message)
          }
        }).catch(r=>{
          this.loading=false
          console.log(r);
        })
      }
      
    },
    // 编辑货运名称
    editFreight(imgPath){
      // 如果编辑的id中有add_ 是新增
      if (('' + this.imgNum).indexOf('add_') > -1) {
        this.addPhotoObj = this.editPhotoObj
        this.addFreight()
        return
      }
      
      if (this.editPhotoObj.value === '自定义' && !this.editPhotoObj.custom) {
        this.$message.error('请输入自定义标签名称')
        return
      }
      const config ={
          orderLogisticsId:this.orderLogisticsId,
          imgDtoList:[{imgName: this.editPhotoObj.value=="自定义"?this.editPhotoObj.custom:this.editPhotoObj.value,imgPath:imgPath, id:this.imgNum}],
      }
       editImg(config).then(res=>{
        if (res.code===200) {
          this.getLogisticsDetail()
          this.getLogistics()
          this.imgNum=null
          this.$message.success(res.message)
        }else{
            this.$message.error(res.message)
        }
      })
    },
    // 删除车辆记录
    delLogistics(idList) {
      let id = idList || this.selectedRowKeys
      if(!Array.isArray(id)) {
        id = [id]
      }
      if (!id.length) {
        this.$message.error('请选择批量删除的记录')
        return
      }
      delLogistics({delList: id,orderId:this.$route.query.id}).then(res => {
        if (res.code === 200) {
          id.forEach(item => {
            if (item === this.orderLogisticsId) {
              this.orderLogisticsId = ''
              this.clear()
            }
          })
          this.selectedRowKeys = []
          this.getLogistics()
          this.getSchedule()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 取消车辆记录
    cancel(id) {
      if (id === 0) {
        const newData = this.data.filter(item => item.id !== id)
        this.Selected = false
        this.data = newData
      } else {
        const target = this.data.find(item => item.id === id)
        Object.keys(target).forEach(key => { target[key] = target._originalData[key] })
        target._originalData = undefined
      }
    },
    // 上传img文件
    getImgFile(e) {
        // this.addPhotoObj.loading = true
        // uploadPhoto(Array.from(e.target.files)[0], (url) => {
        //   this.addPhotoObj.loading = false
        //   this.addPhotoObj.img = url
        // })
        // e.target.value=null
        if (e.target.files[0]) {
          if (e.target.files[0].type.indexOf('image') === -1) {
            this.$message.error('请上传正确的格式')
            e.target.value = null
            return
          }
          if (e.target.files[0].size > 15 * 1024 * 1024) {
            this.$message.error('图片大小不能超过15M')
            e.target.value = null
            return
          }
        uploadPhoto(Array.from(e.target.files)[0], (url) => {
          if (this.imgNum) {
            this.detailData.imgs.forEach(r=>{
                if (r.id==this.imgNum) {
                    r.imgPath=url
                }
            })
            this.editPhotoObj.img=url
          }else{this.addPhotoObj.img = url}
          
        })
        e.target.value=null
        }
    },
    batchUpload () {
      this.$refs.addBatchImage.click()
    },
    getBatchImgFile (e) {
      console.log(e)
      this.loadingBatch = true
      const files = Array.from(e.target.files)
      const config = {
        orderLogisticsId: this.orderLogisticsId,
        imgDtoList: []
      }
      let count = 0
      for (let index = 0; index < files.length; index++) {
        const element = files[index]
        const name = files[index].name.match(/^.*(?=\.)/)
        config.imgDtoList.push({ imgName: name && name[0], imgPath: '' })
        if (element.size > 15 * 1024 * 1024) {
          this.$message.error('图片大小不能超过15M')
          this.loadingBatch = false
          return
        }
        if ((count += element.size) > 20 * 1024 * 1024) {
          this.$message.error('批量图片之和不超过20M')
          this.loadingBatch = false
          return
        }
      }
      uploadBatchPhoto(files, data => {
        console.log(data)
        config.imgDtoList.forEach((item, index) => {
          item.imgPath = data[index]
        })
        addImg(config).then(res => {
          this.loadingBatch = false
          if (res.code === 200) {
            this.getLogisticsDetail()
            this.getLogistics()
            this.clear()
          } else {
            this.$message.error(res.message)
          }
        })
      })
      e.target.value = null
    },
    // 选择图片
    selectImg() {
        this.$refs.addImage.dispatchEvent(new MouseEvent('click')) 
    },
    // 批量操作
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys) 
      this.selectedRowKeys = selectedRowKeys
    },
    toggle (key) {
      const target = this.data.find(item => item.id === key)
      console.log(key, target);
      target._originalData = Object.assign({}, target)
      target.editable = !target.editable
    },
    // table点击事件
    Rowclick (record) {
      return {
        on: {
          click: () => {
            if (record.id && record.id !== this.orderLogisticsId) {
              this.orderLogisticsId = record.id
              this.distance=record.distance
              this.freightLoading = true
              this.getLogisticsDetail()
              this.clear()
            }
          }
        }
      }
    },
    // table颜色
    rowClassName(record) {
      console.log(this.orderLogisticsId);
      return (record.id === this.orderLogisticsId && this.orderLogisticsId !== 0) ? 'check-color' : ''
    },
    show (url) {
      if(!url){
        this.$message.error('请先上传货流照片！')
        return
      }
      this.previewImage = url
      this.imgVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.table-foot {
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  height: 46px;
  line-height: 46px;
  cursor: pointer;
  user-select:none;
}
.freight-photo {
  border: 1px solid #ebebeb;
  position: relative;
  .info {
    height: 40px;
    line-height: 40px;
    background: #fff;
    text-align: center;
    font-size: 16px;
    border-top: 1px solid #f2f2f2;
  }
  .add-info {
    height: 40px;
    background: #fff;
    padding: 0 8px;
    i {
      color:#8400ff;
    }
    .select {
      width: 200px;
    }
  }
  .img-plus {
    background: #f6f6f6;
    width: 100%;
    height: 160px;
    position: relative;
    i {
      font-size: 32px;
      line-height: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .add-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    i {
      font-size: 20px;
    }
  }
  img {
    background: #f6f6f6;
    width: 100%;
    height: 160px;
    transition: all .2s;
  }
  .edit-img:hover {
    opacity: .5;
  }
  &:hover .close{
    opacity: 1;
    transition: all .3s;
  }
  .close {
    opacity: 0;
    position: absolute;
    top: -10px;
    right: -10px;
    background: #aaaaaa;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 12px;
    color: #fff;
    text-align: center;
  }
  .mask {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: all .3s;
    opacity: 0;
    position: absolute;
    top: 0;
    height: 160px;
    width: 100%;
    background: rgba(0, 0, 0, .4);
    font-size: 18px;
  }
  &:hover .mask{
    opacity: 1;
  }
  &:last-child {
    width: 100%;
    min-height: 202px;
    background: #f2f2f2;
    cursor: pointer;
  }
}
/deep/ .ant-card-bordered {
    border: none;
    margin-bottom: 42px;
}
.more {
  text-align: center;
  margin-bottom: 72px;
  button {
    width: 200px;
  }
}
.foot {
  margin-top: 62px;
  margin-bottom: 32px;
  .distance {
    margin: 0 12px;
    display: flex;
    align-items: center;
    .content {
      position: relative;
      margin: 0 32px;
      display: inline-block;
      width: 200px;
      height: 2px;
      background: #1890ff;
      &::after {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #1890ff;
        position: absolute;
        left: -7px;
        top: -2px;
      }
      &::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #1890ff;
        position: absolute;
        right: -9px;
        top: -3px;
      }
      .trucks {
        user-select:none;
        position: absolute;
        top: -45px;
        left: 50%;
        transform: translateX(-50%);
      }
      .arrows {
        user-select:none;
        position: absolute;
        top: -8px;
        right: 0;
      }
      .text {
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  span {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 24px;
    font-weight: 650;
  }
  p {
    width: 130px;
    text-align: center;
  }
}
.percentage {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
}
.weight {
  font-weight: 700;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}

.doc-row{
    .title{
      font-weight: 500;
      font-size: 16px;
    }
    .doc-context{
      margin-top: 10px;
      .doc-info{
        margin-top: 10px;
        .doc-pic{
          border: 1px solid #ebebeb;
          width: 180px;
          height: 120px;
          img{
            object-fit:cover;
            width: 100%;
            height: 100%;
          }
        }
        .doc-name{
          text-align: center;
          padding: 5px 0;
        }
      }
    }
    .doc-right{
      margin-left: 100px;
    }
  }
.row {
  margin-top: 32px;
  /deep/ .ant-col-6 {
      margin-bottom: 20px;
    }
}
/deep/ .check-color {
  background: #e2e8da;
}
/deep/ .ant-table-tbody td {
  cursor: pointer;
}
</style>