var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payment"},[_c('div',{staticClass:"paybody"},[_c('div',{staticClass:"pay-count"},[_c('PayMoney',{staticClass:"pay-item",attrs:{"title":"合同总额","total":_vm.total}}),_c('PayMoney',{staticClass:"pay-item",attrs:{"title":"卖方货款金额","total":_vm.net}}),_c('PayMoney',{staticClass:"pay-item",attrs:{"title":"平台税款税费","total":_vm.tax}})],1)]),_c('div',{staticClass:"order-count"},[_c('a-table',{attrs:{"rowKey":"id","columns":_vm.columns,"dataSource":_vm.list,"pagination":{size:'middle',current: _vm.listInfo.pageNum,pageSize: _vm.listInfo.pageSize,showSizeChanger: true,
            showQuickJumper: true,
            onShowSizeChange: function (current, pageSize) {
              _vm.listInfo.pageSize = pageSize
              _vm.listInfo.pageNum = 1
              this$1.getlist()
            },
            onChange: function (pageNum) {
            _vm.listInfo.pageNum = pageNum 
            this$1.getlist()}
          },"size":"middle"},scopedSlots:_vm._u([{key:"id",fn:function(text){return [_c('a',{staticClass:"id-primary"},[_vm._v(_vm._s(text))])]}},{key:"payStatus",fn:function(text){return _c('span',{},[_c('a-badge',{attrs:{"color":_vm._f("badgeStatus")(text),"text":text}})],1)}},{key:"state",fn:function(text,record){return [(record.state=='success')?_c('span',[_c('a-badge',{attrs:{"status":"success"}})],1):(record.state=='')?_c('span',[_c('a-badge',{attrs:{"status":"error"}})],1):(record.state=='1')?_c('span',[_c('a-badge',{attrs:{"status":"default"}})],1):(record.state=='2')?_c('span',[_c('a-badge',{attrs:{"status":"processing"}})],1):_vm._e(),_c('span',[_vm._v(_vm._s(text))])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }