<template>
  <div class="product-details">
    <div class="details-container">
      <div class="details-info">
        <!-- 导航 -->
        <a-breadcrumb separator=">" class="breadcrumb">
          <a-breadcrumb-item>
            <a @click.prevent="$router.push('/index')">首页</a>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            <a @click.prevent="$router.push('/product-list')">交易中心</a>
          </a-breadcrumb-item>
          <a-breadcrumb-item>商品详情</a-breadcrumb-item>
        </a-breadcrumb>
        <!-- 商品 -->
        <div class="info">
          <!-- 商品图片 -->
          <div class="info-img">
            <div class="img-show" @click="show()">
              <img
                ref="bigImg"
                :src="getImgList.length ? mainImg : noImg"
                alt=""
              />
              <!-- <div class="img-big">
                <img
                  ref="showImg"
                  :src="getImgList.length ? mainImg : noImg"
                  alt=""
                />
              </div> -->
              
            </div>
            <div class="img-list">
              <div
                v-for="(item, index) in getImgList"
                :key="index"
                class="list-border"
                @click="checkImg(index)"
              >
                <img
                  ref="smallImg"
                  :src="item.imgPath"
                  alt=""
                />
              </div>
            </div>
          </div>
          <!-- 商品规则 -->
          <div class="info-rule">
            <!-- 商品标题 -->
            <div class="l-flex-between">
              <div class="rule-title">
                <b>{{ list.mdseName }}</b>
                <p>上架时间：{{list.createdTime}}</p>
              </div>
              <div class="rule-price">
                <span v-if="list.unitPrice">¥<b>{{ list.unitPrice.toFixed(2) }}</b></span
                >/{{list.unitsMeasure | unit}}
              </div>
            </div>
            <!-- 商品规格 -->
            <div class="rule-info">
              <a-row class="result" type="flex">
                <a-col :span="3">卖家信息：</a-col>
                <a-col :span="21"><a :href="`/shop/${'s'+list.supplierId}`" target="_blank"><a-tag color="red" >{{ list.supplierName }}</a-tag></a></a-col>

                <a-col :span="3">关联个人：</a-col>
                <a-col :span="21">{{ list.legalName }}</a-col>

                <a-col :span="3">发货地：</a-col>
                <a-col :span="21">{{ list.sourceAddress }}</a-col>

                <a-col :span="3">库存({{list.unitsMeasure | unit}})：</a-col>
                <a-col :span="21">{{ list.quantity }}</a-col>

                <a-col :span="3">发票种类：</a-col>
                <a-col :span="21">
                  <a-select class="select" v-model="form.taxRate" @change="getTaxes()" placeholder="请选择对应的区域">
                    <a-select-option :value="item.id" v-for="item in list.taxRegionPolicys" :key="item.id">
                      {{item.policyName}}
                    </a-select-option>
                  </a-select>
                </a-col>

                <a-col :span="3">数量：</a-col>
                <a-col :span="21">
                  <a-input-number
                    v-model="form.quantity"
                    :min="list.minQuantity"
                    :max="list.quantity"
                    placeholder="输入数量"
                    :precision="list.unitsMeasure === 'S' || list.unitsMeasure === 'C' ? 0 : 4"
                    @blur="quantityBlur"
                    style="width:140px"
                  />
                  <a-space :size="24">
                    <span style="color: #999">&nbsp;&nbsp;{{ list.unitsMeasure | unit }}</span>
                  </a-space>
                  <span v-if="form.quantity < list.minQuantity || form.quantity > list.maxQuantity" class="error-info">* 最低采购数量不得小于 {{list.minQuantity}}， 最大采购数量不得大于 {{list.maxQuantity}}，输入内容长度不得大于10位</span>

                </a-col>

                <a-col :span="3">{{!cut ? "商品总价：" : "价税合计："}}</a-col>
                <a-col :span="21">
                  <a-input-number v-if="!cut" style="width:180px" v-model="form.totalPrice" :max="5000000" @change="minFloatUnitPrice && getTaxes()" :precision="2">
                  </a-input-number>
                  <a-input-number v-else style="width:180px" v-model="taxes.contractAmount" @change="getTaxes('contractAmount')" :precision="2">
                  </a-input-number>
                  <div class="input-number-slot">
                    <span style="padding: 0 10px"> 元 </span>
                  </div>
                  <span v-if="!minFloatUnitPrice" class="error-info">* 您输入的总价不符合该商品的浮动单价区间，请与卖家协商确认后再下单</span>
                </a-col>
              </a-row>

              <div class="rule-result">
                <div class="label">{{cut ? "商品总价" : "价税合计"}}</div>
                <span class="result">
                <span class="price">{{((cut ? form.totalPrice : taxes.contractAmount) || 0).toFixed(2)}}</span>元
                </span>
                <a-button class="rule-swap" @click="cut = !cut"><a-icon type="swap" :rotate="90"/></a-button>
              </div>

              <div class="rule-result">
                <div class="label">预计税费</div>
                <span class="result">
                  附加税<span class="price">{{taxes.surcharge}}</span>元
                </span>
                <span class="result">
                  增值税<span class="price">{{taxes.vat}}</span>元
                </span>
                <span class="red">最终按税务机关依法依规进行征收</span>
              </div>

              <div>
                <a-space :size="18" style="margin-top: 22px">
                  <a-button type="danger" :disabled="checkEntity.entityCharacter === 'S' || (checkEntity.entityCharacter === 'P' && !list.isAllowPurchase)" class="btn" size="large" :loading="cartLoading" ghost @click="addCart">加入购物车</a-button>
                  <a-button
                    class="btn"
                    type="primary"
                    size="large"
                    @click="promptly"
                    :disabled="!minFloatUnitPrice || checkEntity.entityCharacter === 'S' || form.quantity > list.maxQuantity || (checkEntity.entityCharacter === 'P' && !list.isAllowPurchase)"
                  >
                    立即采购
                  </a-button>
                  <span v-if="checkEntity.entityCharacter === 'S'" class="error-info" style="display:inline-block;">* 请切换买家身份进行采购！</span>
                  <span v-if="checkEntity.entityCharacter === 'P' && !list.isAllowPurchase" class="error-info" style="display:inline-block;">* 买家和卖家不属于同一个集团</span>
                </a-space>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="details">
      <div class="details-left">
        <a-card title="商品详情" style="margin-bottom: 20px">
          <div class="details-size">
            <div class="label">分类</div>
            <div class="span">{{list.mdseName && list.mdseName.split(' ')[0]}}</div>
          </div>

          <div class="details-size">
            <div class="label">货源地</div>
            <div class="span">{{list.sourceAddress}}</div>
          </div>
          
          <div class="details-size" v-for="item in (list.attributes && Object.keys(list.attributes)) || []" :key="item">
            <div class="label">{{item}}</div>
            <div class="span">{{list.attributes[item]}}</div>
          </div>

          <div>
            <div class="details-size">
              <div class="label">开票品名</div>
              <div class="span">{{list.invoiceName}}</div>
            </div>

            <div class="details-size">
              <div class="label">开票规格</div>
              <div class="span">{{list.specName}}</div>
            </div>
          </div>
          <div>
           <b class="details-title">商品详情内容</b>
          </div>
        </a-card>
        <a-card title="注意事项">
          <ul class="notes">
            <li>
              关于电子合同签署
              <p class="indent">
                平台生成订单时会自动生成该订单的电子合同，并通过短信将签署链接发送给买卖双方签署人手机上，签署人点击并通过验证后，可在手机上进行手签确认，只有签署电子合同后，方可进行订单支付。
              </p>
            </li>
          </ul>
        </a-card>
      </div>
      <!-- <div class="details-right">
        <a-card title="帮助中心">
          <a v-for="(item, index) in helpList" :key="index" :href="item.url" target="_blank">
            <div class="img-chunk">
              <div class="fl problem" :class="item.className" alt=""/>
              <div>
                <p class="title-n">{{item.title}}</p>
                <p class="title-t">{{item.text}}</p>
              </div>
            </div>
          </a>
        </a-card>
      </div> -->
    </div>

    <a-modal :width="800" :visible="imgVisible" :footer="null" @cancel="imgVisible = false">
      <img alt="商品图片" style="width: 100%" :src="previewImage" />
    </a-modal>
    <a-modal class="cart" :width="533" title="添加成功" centered :visible="cartVisible" :footer="null" @cancel="cartVisible = false">
      <a-row :gutter="32">
        <a-col :span="24" align="center">
          <a-space class="cart-hint">
            <a-icon type="check-circle" theme="filled" />
            <span>商品已成功加入购物车！</span>
          </a-space>
        </a-col>
        <a-col :span="12" align="right" @click="cartVisible = false"><a-button>继续采购</a-button></a-col>
        <a-col :span="12" align="left"><a-button type="primary" @click="$router.push('/cart')">去购物车结算</a-button></a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { purchase } from '../../api/public'
import { type } from "@/utils/constArr";
import {evaluation} from '@/api/seller.js'
import {confirm} from '@/api/order.js'
import {cartAdd} from '@/api/cart'
import {mapState,mapGetters} from 'vuex'
import cookie from '@/utils/js-cookie.js'


export default {
  data() {
    return {
      noImg: require('../../assets/images/noImg.png'),
      num: null,
      helpList: [
        {title: '卖家入驻', text: '卖家入驻细节介绍', className: 'problem-seller', url: 'https://www.taxoss.zz91.cn/prod/helpcenter/卖家入驻.pdf'},
        {title: '商品管理', text: '发布及上下架流程', className: 'problem-merchandise', url: 'https://www.taxoss.zz91.cn/prod/helpcenter/商品管理.pdf'},
        {title: '订单采购', text: '采购商品及订单支付', className: 'problem-order', url: 'https://www.taxoss.zz91.cn/prod/helpcenter/订单采购.pdf'},
        {title: '支付操作', text: '支付及开通方法', className: 'problem-pay', url: 'https://www.taxoss.zz91.cn/prod/helpcenter/支付操作.pdf'},
      ],
      taxRate: [],
      list: {
        merchandiseImages: []
      },
      mainImg: '',
      checkEntity: cookie.get('checkEntity') || {},
      // purchaserId: storeLocal.get('checkEntity'), // 买家id
      form: {
        taxRate: undefined,
        quantity: null,
        totalPrice: null
      },
      taxes: {
        vat: '',
        surcharge: '',
        contractAmount: ''
      },
      cut: false,
      imgVisible: false,
      previewImage: null,
      cartVisible: false, // 购物车控制
      cartLoading: false, // loading
    }
  },
  created() {
    this.getpur()
  },
  computed: {
    ...mapState(['hasLogin']),
    ...mapGetters(['getRoleP']),
    minFloatUnitPrice() {
      if (!this.form.quantity && !this.form.totalPrice) return this.list.minFloatUnitPrice
      const min = Math.round(this.form.totalPrice / this.form.quantity * 1000) / 1000
      console.log(min)
      if (min >= this.list.minFloatUnitPrice) {
        return true
      } else {
        return false
      }
    },
    getImgList() {
      return this.list.merchandiseImages && this.list.merchandiseImages.length ? this.list.merchandiseImages.slice(0, 5) : []
    }
  },
  filters: {
    unit: (val) => type[val]
  },
  methods: {
    getpur() {
      purchase(this.$route.query.id, this.checkEntity.entityCharacter === 'P' ? this.checkEntity.entityId : undefined).then((res) => {
        this.list = res.data
        if (this.list.merchandiseImages) {
          const img = this.list.merchandiseImages.find(item => item.isMain == 1)
          this.mainImg = img ? img.imgPath : (this.list.merchandiseImages[0] || {}).imgPath
        }
        this.list.attributes = JSON.parse(res.data.attributes)
        this.form.quantity = res.data.quantity > res.data.maxQuantity ? res.data.maxQuantity : res.data.quantity
        this.form.totalPrice = res.data.totalPrice
        console.log(this.form.totalPrice,res.data.unitPrice,this.form.quantity)
        const taxRate = this.list.feeEvaluationDtoList && this.list.feeEvaluationDtoList[0] || {}
        this.form.taxRate = taxRate.taxPolicyId
        console.log('??',this.form.taxRate);
        // this.taxes.surcharge = taxRate.addTaxAmount
        // this.taxes.vat = taxRate.vatAmount
        // 计算税费
        this.getTaxes()
      })
    },
    checkImg(index) {
      this.$refs.bigImg.src = this.$refs.smallImg[index].src
      // this.$refs.showImg.src = this.$refs.smallImg[index].src
    },
    quantityBlur() {
      this.form.totalPrice = this.form.quantity === this.list.quantity ? this.list.totalPrice : Math.round(this.form.quantity * this.list.unitPrice * 100) / 100
      console.log(222,this.cut);
      this.getTaxes(this.cut && 'netAmount')
    },
    // 立即采购
    promptly() {
      const data = cookie.get('checkEntity')
      if (!data || (data.entityCharacter === 'S' && data.entityId)) {
        this.$message.error('请切换买家身份进行采购！')
        return
      }
      const config = {
          merchandiseId: this.list.id,
          purchaserId: data.entityId,
          taxRegionPolicyId: this.form.taxRate,
          quantity: this.form.quantity,
          grossAmount: this.form.totalPrice
      }
      console.log(config)
      confirm(config).then(res => {
        if (res.code === 200) {
          this.$router.push({
            path: '/fill-order',
            query: config
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    async getTaxes(str) {
        const taxRate = this.list.taxRegionPolicys.find(item => item.id === this.form.taxRate)
        if (!taxRate) return
        // // 增值税
        // const vat = await taxCalculate({taxType: 'VAT', totalAmount: this.form.totalPrice, vatRate: taxRate.vatRate, surcharge: taxRate.addRate})
        // // 附加税
        // const surcharge = await taxCalculate({taxType: 'SURCHARGE', totalAmount: this.form.totalPrice, vatRate: taxRate.vatRate, surcharge: taxRate.addRate})
        const grossAmount = this.cut && str !== 'netAmount' ? this.taxes.contractAmount : this.form.totalPrice
        const estimateModel = this.cut && str !== 'netAmount' ? 'contractAmount' : 'netAmount'
        evaluation(this.list.supplierId, grossAmount, taxRate.id, estimateModel).then(res => {
          const data = res.data[0]
          if (data) {
            this.taxes.surcharge = data.addTaxAmount
            this.taxes.vat = data.vatAmount
            if (str !== 'contractAmount' || str === 'netAmount') {
              this.taxes.contractAmount =  data.contractAmount
            } else {
              this.form.totalPrice = data.netAmount
            }
          }
        })
    },
    show () {
      console.log('!!!!!');
      this.previewImage = this.$refs.bigImg.src
      this.imgVisible = true
    },
    // 加入购物车
    addCart() {
      const data = cookie.get('checkEntity')
      if (!data || (data.entityCharacter === 'S' && data.entityId)) {
        this.$message.error('请切换买家身份进行采购！')
        return
      }
      const config = {
        purchaserId: data.entityId,
        taxRegionPolicyId: this.form.taxRate,
        mdseId: this.list.id,
        quantity: this.form.quantity,
        // addUnitPrice: this.list.unitPrice,
        addTotalPrice: this.form.totalPrice
      }
      this.cartLoading = true
      cartAdd(config).then(res => {
        this.cartLoading = false
        if (res.code === 200) {
          this.getpur()
          this.cartVisible = true
          // 获取购物车数量
          this.$store.dispatch('cartAmount')
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.product-details {
  .details-container {
    background: #fff;
    padding-bottom: 50px;
    min-width: 990px;
  }
  .details-info {
    min-width: 990px;
    margin: 0 auto;
    .info {
      display: flex;
      justify-content: center;
      .info-img {
        width: 510px;
        img {
          position: relative;
        }
        .img-show {
          cursor: pointer;
          margin-bottom: 16px;
          position: relative;
          .img-big {
            display: none;
            content: '';
            position: absolute;
            padding: 8px;
            background: #f2f2f2;
            top: 0;
            right: -724px;
            z-index: 999;
            img {
              width: 700px;
            }
          }
          &:hover {
            .img-big {
              display: block;
              @media screen and (max-width: 1440px){
                display: none;
              }
            }
            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              border: 2px solid #e67757;
            }
          }
          img {
            object-fit: cover;
            width: 100%;
            height: 450px;
          }
        }
        .img-list {
          .list-border {
            margin-right: 10px;
            display: inline-block;
            cursor: pointer;
            position: relative;
            height: 80px;
            width: 80px;
            &:hover {
              &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                border: 2px solid #e67757;
              }
            }
          }
          img {
            object-fit: cover;
            display: inline-block;
            height: 80px;
            width: 80px;
          }
        }
      }
      .info-rule {
        width: 690px;
        @media screen and (max-width: 1440px){
          margin-left: 8px
        }
        margin-left: 42px;
        .rule-title {
          margin-bottom: 16px;
          b {
            font-weight: 700;
            font-size: 18px;
            line-height: 36px;
          }
          p {
            line-height: 36px;
            color: #999999;
            font-size: 16px;
          }
        }
        .rule-price {
          color: #999999;
          font-size: 14px;
          span {
            color: @error;
            b {
              font-size: 28px;
            }
          }
        }
        .rule-info {
          // padding-left: 16px;
          font-size: 14px;
          .l-flex {
            margin: 14px;
            line-height: 30px;
          }
          .result {
            @media screen and (max-width: 1440px){
              margin-left: 4px;
            }
            margin-left: 24px;
          }
          .rule-result {
            margin-top: 24px;
            background-color: #f9f9f9;
            border: 1px solid #f2f2f2;
            padding: 0 14px;
            line-height: 42px;
            font-size: 14px;
            position: relative;
            .result {
              margin-left: 12px;
              color: #999999;
              .price {
                font-size: 20px;
                color: #333333;
                margin: 0 14px;
              }
            }
            .rule-swap {
              position: absolute;
              padding: 0 10px;
              top: -40px;
              left: 28px;
            }
            .red {
              color: @error;
              font-size: 16px;
              margin-left: 12px;
            }
          }
          .pay {
            margin-top: 22px;
            margin-left: 105px;
            margin-right: 24px;
            width: 150px;
          }
          .btn {
            width: 150px;
          }
          .label {
            min-width: 72px;
            margin-left: 8px;
            display: inline-block;
          }
          .select {
            width: 240px;
          }
        }
      }
    }
  }
  .details {
    margin: 0 auto;
    max-width: 1440px;
    min-width: 990px;
    margin-top: 24px;
    margin-bottom: 120px;
    display: flex;
    .details-left {
        width: 100%;
      // width: 1190px;
      @media screen and (max-width: 1440px){
        width: 100%;
        padding: 0 15px;
      }
      display: inline-block;
      .details-size {
        display: inline-block;
        border-bottom: 1px solid #f2f2f2;
        line-height: 44px;
        font-size: 14px;
        width: 362px;
        margin-right: 9px;
        margin-bottom: 16px;
        div {
          display: inline-block;
        }
        .label {
          color: #999;
          width: 68px;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
      .details-title {
        color: #999999;
        font-size: 16px;
      }
    }
    .details-right {
      width: 270px;
      @media screen and (max-width: 1440px){
        display: none;
      }
      margin-left: 20px;
      display: inline-block;
      .recommend-chunk {
        margin-bottom: 20px;
        width: 100%;
        height: 190px;
        border: 1px solid #f2f2f2;
        display: flex;
        justify-content: center;
        align-items: center;
        .img {
          font-size: 42px;
          text-align: center;
        }
        .error-font {
          color: @error;
        }
        p {
          margin-top: 16px;
          font-size: 14px;
          text-align: center;
        }
      }
      .img-chunk {
        margin-bottom: 20px;
        width: 100%;
        border: 1px solid #f2f2f2;
        height: 90px;
        padding: 20px 0;
        line-height: 24px;
        img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          margin: 0 12px;
        }
        .fl {
          width: 50px;
          height: 50px;
          font-size: 40px;
          margin: 0 12px;
          text-align: center;
          line-height: normal;
          color: #F04844;
        }
        .title-n {
          font-size: 18px;
          color: #999999;
        }
        .title-t {
          color: #999999;
        }
      }
    }
  }
}
/deep/ .ant-card-head {
  background: #f9f9f9;
  color: #999999;
}
/deep/ .ant-input-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-number-slot {
  background: #f2f2f2;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  vertical-align: top;
  // padding: 0 8px;
  border: 1px solid #d9d9d9;
  border-left: none;
  border-radius: @border-radius-base;
  /deep/ .ant-select-selection {
    background-color: transparent;
    border: none;
  }
  /deep/ .ant-select-open .ant-select-selection {
    border: none;
    box-shadow: none;
  }
  /deep/ .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    border: none;
    box-shadow: none;
  }
}
.error-info {
  color: rgb(240, 72, 68);
  margin-top: 4px;
  line-height: 14px;
  display: block;
}
/deep/ .ant-col {
  line-height: 32px;
  margin-bottom: 12px;
}
.breadcrumb{
  width: 100%;
  font-size: 14px;
}
.notes {
  padding: 0 20px;
  .indent {
    text-indent: 2em;
  }
  li {
    list-style: initial;
    font-weight: 700;
    line-height: 28px;
    color: #999999;
    margin-bottom: 28px;
    p {
      font-weight: 400;
    }
  }
}
/deep/ .ant-tag {
  cursor: pointer;
}
.cart {
  .cart-hint {
    font-size: 18px;
    margin-top: 12px;
    margin-bottom: 24px;
    i {
      font-size: 31px;
      color: #52c41a;
    }
  }
  button {
    width: 121px;
  }
}
</style>