<template>
  <a-card class="release-success">
    <p><a-icon type="check-circle" class="icon" /></p>
    <p><span class="success">商品发布成功</span></p>
    <p><span class="info">您可以发送以下链接给买方，以便于进行快速交易。</span></p>
    <p><router-link :to="`/product-details?id=` + id" target="_Blank" class="site">{{href()}}</router-link> <span style=" cursor: pointer;" @click="copyActiveCode">  复制</span></p>

    <a-space class="success-button">
      <a-button type="primary" @click="$router.push('/')">进入首页</a-button>
      <a-button @click="$router.push(`/product-details?id=` + id)">查看商品</a-button>
    </a-space>

    <p class="hint"><a-icon type="info-circle" /> 温馨提示：91再生网不会以订单异常、系统升级为由要求您点击任何网址链接进行退款操作。</p>
  </a-card>
</template>

<script>
import Clipboard from 'clipboard'
export default {
  props: ['id'],
  methods: {
    copy() {
      let transfer = document.createElement('input');
      document.getElementsByClassName('release-success')[0].appendChild(transfer);
      transfer.value = this.href();  // 这里表示想要复制的内容
      transfer.focus();
      transfer.select();
      if (document.execCommand('copy')) {
          document.execCommand('copy');
      }
      transfer.blur();
      document.getElementsByClassName('release-success')[0].removeChild(transfer);
    },
    href() {
      return `${window.location.origin}/product-details?id=` + this.id
    },
        // 复制签署链接
    copyActiveCode (e) {
        const clipboard = new Clipboard(e.target, { text: () => `${window.location.origin}/product-details?id=` + this.id})
        clipboard.on('success', () => {
          this.$notification.success({
            message: '成功',
            description: '商品地址已复制，您可以前往分享'
          })
          // 释放内存
          clipboard.off('error')
          clipboard.off('success')
          clipboard.destroy()
        })
        clipboard.on('error', () => {
          // 不支持复制
          this.$notification.error({
            message: '失败',
            description: '该浏览器不支持自动复制'
          })
          // 释放内存
          clipboard.off('error')
          clipboard.off('success')
          clipboard.destroy()
        })
        clipboard.onClick(e)
      },
  }
}
</script>

<style lang="less" scoped>
.release-success {
  width: 910px;
  height: 750px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media screen and (max-width: 1440px){
      margin: 0 auto;
  }
  .icon {
    font-size: 100px;
    line-height: 120px;
    color: #0FCE96;
    margin-bottom: 12px;
  }
  .success {
    font-size: 24px;
    line-height: 36px;
  }
  .info {
    font-size: 16px;
    color: #999999;
    line-height: 36px;
  }
  .success-button {
    margin-top: 80px;
    button {
      width: 200px;
      height: 45px;
    }
  }
  .hint {
    margin-top: 24px;
    color: #999999;
    line-height: 24px;
    font-size: 14px;
  }
  /deep/ .ant-card-head {
      background: #e9e9e9;
  }
}
</style>