<template>
  <div class="main">
    <div class="container-title">
      <a-breadcrumb separator=">" class="breadcrumb">
        <a-breadcrumb-item>
          <a @click.prevent="$router.push('/index')">首页</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          购物车
        </a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="container">
      <a-card :title="`购物车（有效商品${$store.state.cartAmount - expired}）`" ref="cart">
        <a-space slot="extra" :size="24">
          <span>已选商品 <span class="sum f24">{{selected}}</span> 件</span><span class="sum f20">￥ {{taxAll}}</span>
          <a-button @click="clearing" :loading="loadingClearing" :disabled="$store.state.cartAmount == 0">结算</a-button>
        </a-space>
        <!-- :row-selection="rowSelection" :expandIcon="() => false"  -->
        <a-table rowKey="id" :expandedRowKeys="expandedRowKeys" :dataSource="scList" :columns="columns"
          childrenColumnName="goods" :row-selection="rowSelection" :expandIcon="() => false" :loading="loadingTable" size="middle"
          :pagination="false">
          <template slot="supplierName" slot-scope="text, row">
            <span v-if="row.goods">{{text}}</span>
            <a-space v-else style="align-items: initial;">
              <!-- {{row.ids}} -->
              <!-- {{selectedCustomRowKeys}} -->
              <a-checkbox
                :checked="selectedRowKeys.toString().split(',').includes(row.id.toString()) || selectedCustomRowKeys[row.index].split(',').some(item => item == row.id)"
                @change="selectedCustom($event, row)"></a-checkbox>
              <div class="mdse-css">
                <a-avatar shape="square" class="square" :size="70" @click="toDetail(row.mdseId)" :src="row.coverPic || noImg" />
                <div>
                  <p class="name"><a @click="toDetail(row.mdseId)">{{text}}</a></p>
                  <p class="hint">开票品类： {{row.invoiceName}}</p>
                  <p class="hint">规格： {{row.attributes && row.attributes['规格型号']}}</p>
                </div>
              </div>
            </a-space>
          </template>
          <template slot="sum" slot-scope="text">
            <span class="sum" v-if="text">￥{{text}}</span>
          </template>
          <template slot="quantity" slot-scope="text">
            <span>{{text}}</span>
          </template>
          <template slot="active" slot-scope="text, row">
            <a-popconfirm slot="extra" title="是否移除此商品？" placement="topRight" @confirm="cartDel(row.id)">
                <a v-if="!row.goods">删除</a>
            </a-popconfirm>
          </template>
        </a-table>
      </a-card>
      <a-card class="handle">
        <a-row type="flex" justify="space-between">
          <a-col>
            <a-space>
              <a-checkbox :checked="isAllSelect" @click="allSelect">全选</a-checkbox>
              <a-popconfirm v-if="isAllSelect || selectedCustomRowKeys.filter(i => i != -1 && i)" slot="extra" title="是否移除选中商品？" placement="top" @confirm="cartDel(cartSelectDel(), true)">
                <a>删除</a>
              </a-popconfirm>
              <a v-else @click="$message.error('请先选择商品')">删除</a>
            </a-space>
          </a-col>
          <a-col>
            <a-space class="space-end">
              <span>已选商品 <span class="sum f24">{{selected}}</span> 件</span>
              <span>合计（货款金额）：<span class="sum f24">￥ {{taxAll}}</span></span>
              <a-button @click="clearing" :loading="loadingClearing" :disabled="$store.state.cartAmount == 0">结算</a-button>
            </a-space>
          </a-col>
        </a-row>
      </a-card>

      <a-card :title="`购物车（失效商品${expired}）`" class="invalid">
        <a-popconfirm slot="extra" title="是否清除所有已失效的商品？" placement="topRight" @confirm="cartIznvalidDel">
          <a>清除失效商品</a>
        </a-popconfirm>
        <a-table rowKey="id" :expandedRowKeys="expandedRowKeysDis" :dataSource="disList" :columns="columns" defaultExpandAllRows childrenColumnName="goods" :loading="loadingTable"
          :expandIcon="() => false" size="middle" :pagination="false">
          <template slot="supplierName" slot-scope="text, row">
            <span v-if="row.goods">{{text}}</span>
            <a-space v-else style="align-items: initial;margin-left: 24px">
              <div class="mdse-css">
                <a-avatar shape="square" class="square" :size="70" :src="row.coverPic || noImg" />
                <div>
                  <p class="name">{{text}}</p>
                  <p class="hint">开票品类： {{row.invoiceName}}</p>
                  <p class="hint">规格： {{row.attributes && row.attributes['规格型号']}}</p>
                </div>
              </div>
            </a-space>
          </template>
          <template slot="sum" slot-scope="text">
            <span class="sum" style="color: #AAAAAA" v-if="text">￥{{text}}</span>
          </template>
          <template slot="quantity" slot-scope="text">
            <span>{{text}}</span>
          </template>
          <template slot="active" slot-scope="text, row">
            <span style="color: #AAAAAA" v-if="!row.children">失效</span>
          </template>
        </a-table>
      </a-card>
      <a-card v-show="fixed" :class="'fixed'">
        <a-row type="flex" justify="space-between">
          <a-col>
            <a-space>
              <a-checkbox :checked="isAllSelect" @click="allSelect">全选</a-checkbox>
              <a-popconfirm v-if="isAllSelect || selectedCustomRowKeys.filter(i => i != -1 && i)" slot="extra" title="是否移除选中商品？" placement="top" @confirm="cartDel(cartSelectDel(), true)">
                <a>删除</a>
              </a-popconfirm>
              <a v-else @click="$message.error('请先选择商品')">删除</a>
            </a-space>
          </a-col>
          <a-col>
            <a-space class="space-end">
              <span>已选商品 <span class="sum f24">{{selected}}</span> 件</span>
              <span>合计（货款金额）：<span class="sum f24">￥ {{taxAll}}</span></span>
              <a-button @click="clearing" :loading="loadingClearing" :disabled="$store.state.cartAmount == 0">结算</a-button>
            </a-space>
          </a-col>
        </a-row>
      </a-card>
    </div>
  </div>
</template>

<script>
import { cartList, cartDel, cartIznvalidDel } from "@/api/cart";
import { cartPreview } from '@/api/cart'
import { type } from '@/utils/constArr'
import cookie from '@/utils/js-cookie.js'
const columns = [
  {
    title: '商品信息',
    dataIndex: 'title',
    scopedSlots: { customRender: 'supplierName' }
  },
  {
    title: '货款单价',
    dataIndex: 'unitPrice',
    align: 'right'
  },
  {
    title: '数量',
    dataIndex: 'quantity',
    scopedSlots: { customRender: 'quantity' },
    align: 'right'
  },
  {
    title: '税收政策',
    dataIndex: 'taxRate',
    align: 'center'
  },
  {
    title: '货款金额',
    dataIndex: 'totalPrice',
    scopedSlots: { customRender: 'sum' },
    align: 'right'
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'active' }
  },
]
export default {
  name: 'NewOrder',
  data() {
    return {
      noImg: require('@/assets/images/noImg.png'),
      loadingTable: false,
      loadingClearing: false,
      columns,
      type,
      fixed: false,
      selectedRowKeys: [],
      selectedRows: [],
      selectedCustomRowKeys: [],
      scList: [], // 有效的购物车列表
      disList: []
    }
  },
  computed: {
    isAllSelect() {
      const arr1 = this.scList.map(item => item.id)
      if (arr1.length == 0 && this.selectedRowKeys.length == 0) {
        return false
      }
      return this.isInclude(this.scList.map(item => item.id), this.selectedRowKeys)
    },
    selected() {
      // console.log('触发2222', this.custom, this.auto);                                                                                            
      const count = [...this.custom, ...this.auto]
      return count.length
    },
    expired () {
      let value = 0
      this.disList.forEach(item => {
        value += item.goods.length
      })
      return value
    },
    custom () {
      return this.selectedCustomRowKeys.toString().split(',').filter(item => item && item != -1 && item)
    },
    auto () {
      return this.selectedRowKeys && this.selectedRowKeys.toString().split(',').filter(item => item && item) || []
    },
    taxAll() {
      let value = 0
      const arr2 = [...this.auto, ...this.custom]
      console.log(909090, this.selectedCustomRowKeys, arr2);
      let goods = []
      this.scList.forEach(item => {
        goods = [...goods, ...item.goods]
      })
      console.log(goods);
      for (let i = 0; i < goods.length; i++) {
        const objI = goods[i]
        for (let j = 0; j < arr2.length; j++) {
          const objJ = arr2[j]
          if (objI.id === objJ) {
            value += objI.totalPrice
          }
        }
      }
      return value.toFixed(2)
    },
    expandedRowKeys() {
      return this.scList.map(item => item.id)
    },
    expandedRowKeysDis() {
      return this.disList.map(item => item.id)
    },
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          let index = null
          const isChecked = selectedRowKeys.length > this.selectedRowKeys.length
          console.log('手动设置', this.selectedRows);
          if (isChecked) {
            index = (selectedRows.find(item => item.id == selectedRowKeys[selectedRowKeys.length - 1]) || {}).index
          } else {
            console.log(999,);
            index = (this.selectedRows.find(item => item.id == this.selectedRowKeys[this.selectedRowKeys.length - 1]) || {}).index
          }
          console.log(666666666, index);
          index !== undefined && (this.selectedCustomRowKeys[index] = '')
          // 触发计算属性 
          this.selectedCustomRowKeys.push('-1')
          console.log('事件选择', selectedRowKeys, selectedRows, index);
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
          this.$forceUpdate()
        },
        getCheckboxProps: record => {
          // console.log(200099, this.selectedCustomRowKeys[record.index].split(','));
          // console.log(22332,this.selectedCustomRowKeys[record.index].split(',').some(i => i && record.id.indexOf(i + ',') > -1));
          // const is = this.selectedCustomRowKeys[record.index].split(',').some(i => i && (record.id.indexOf(i + ',') > -1))
          return {
            props: {
              disabled: !record.supplierId,
            },
            // class: is && 'typesss',
            style: {
              display: !record.supplierId && 'none'
            }
          }
        },
      };
    }
  },
  mounted() {
    this.cartList(true)
    this.$nextTick(() => {
      if (this.$refs.cart.$el.getBoundingClientRect().bottom > document.body.offsetHeight) {
        this.fixed = true
      }
      window.addEventListener("scroll", this.handleFixedTops, true);
    })
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleFixedTops, true)
  },
  methods: {
    cartSelectDel () {
      const p = this.selectedRowKeys.toString().split(',').filter(i => i && i).toString()
      const n = this.selectedCustomRowKeys.filter(i => i != -1 && i).toString()
      console.log(22222, n, p + (n ? (p && ',' || '') + n : ''));
      return p + (n ? (p && ',' || '') + n : '')
    },
    cartList(params1) {
      this.loadingTable = true
      cartList({ purchaserId: (cookie.get('checkEntity') || {}).entityId }).then(res => {
        this.loadingTable = false
        if (res.code === 200) {

          res.data.scList.forEach((item, index) => {
            const p_ids = item.goods.map(i => i.id).toString()
            item.title = item.supplierName
            item.taxRate = item.policyName
            item.quantity = item.quantityUnit
            item.index = index
            item.id = p_ids + ','
            params1 === true && this.selectedRowKeys.push(item.id)

            item.goods.forEach(i => {
              i.ids = item.goods.map(i => i.id.toString())
              i.id = i.id.toString()
              i.index = index
              i.taxRate = item.policyRate
              i.unitPrice = i.unitPrice.toFixed(2)
              i.totalPrice = Math.round((i.totalPrice) * 100) / 100;
            })
            // this.selectedCustomRowKeys.push('')
            this.$set(this.selectedCustomRowKeys, index,  '') 
            // item.goods.length ===1 && (item.goods = [...item.goods, ...item.goods])
          })
          res.data.disList.forEach((item) => {
            const p_ids = item.goods.map(i => i.id).toString()
            item.title = item.supplierName
            item.taxRate = item.policyName
            item.quantity = item.quantityUnit
            // item.index = index
            item.id = p_ids + ','
            
            item.goods.forEach(i => {
              // i.ids = item.goods.map(i => i.id.toString())
              // i.id = i.id.toString()
              // i.index = index
              i.taxRate = item.policyRate
              i.unitPrice = i.unitPrice.toFixed(2)
              i.totalPrice = Math.round((i.totalPrice) * 100) / 100;
            })
          })
          this.$nextTick(() => {
            setTimeout(() => {
              this.handleFixedTops()
            }, 200)
          })
          this.scList = res.data.scList
          this.disList = res.data.disList
        } else {
          this.$message.error(res.message)
        }
      })
    },
    clearing () {
      // ({ purchaserId: 17, previewItems: [{ shoppingCartIds: [1] }, { shoppingCartIds: [9] }] })
      console.log(this.custom, this.selectedCustomRowKeys);
      const data = cookie.get('checkEntity')
      if (!data || (data.entityCharacter === 'S' && data.entityId)) {
        this.$message.error('请切换对应买家身份进行操作！')
        return
      }
      const custom = []
      this.selectedCustomRowKeys.forEach(item => {
        if (item && item != -1) {
          custom.push({shoppingCartIds: item.split(',')})
        }
      })
      const selectedRowKeys = this.selectedRowKeys.map(item => {
        const i = item.split(',').filter(i => i && i)
        return {
          shoppingCartIds: i
        }
      })
      const previewItems = [...custom, ...selectedRowKeys]
      const config = {
        purchaserId: (cookie.get('checkEntity') || {}).entityId,
        previewItems
      }
      console.log(config);
      this.loadingClearing = true
      cartPreview(config).then(res => {
        console.log(res);
        this.loadingClearing = false
        if (res.code === 200) {
          const query = {
            purchaserId: config.purchaserId,
            custom: this.selectedCustomRowKeys.filter(item => item && item != -1).join('|'),
            selectedRowKeys: this.selectedRowKeys.join('|')
        }
          this.$router.push({ path: `fill-order?type=cart`, query})
        } else {
          this.$message.error(res.message)
        }
      })
    },
    cartDel (id, isAll) {
      const data = cookie.get('checkEntity')
      if (!data || (data.entityCharacter === 'S' && data.entityId)) {
        this.$message.error('请切换对应买家身份进行操作！')
        return
      }
      if(!id) {
        this.$message.error('请先选择商品')
        return
      }
      cartDel({purchaserId: (cookie.get('checkEntity') || {}).entityId, id}).then(res => {
        if (res.code === 200) {
          if (isAll) {
            this.selectedCustomRowKeys = this.selectedCustomRowKeys.map(() => '')
            this.selectedRowKeys = []
          } else {
            this.selectedRowKeys.forEach((item, index) => {
              if (item.split(',').includes(id)) {
                this.selectedRowKeys.splice(index, 1)
              }
            })
            this.selectedCustomRowKeys = this.selectedCustomRowKeys.map(item => {
              const i = item.split(',').filter(i => i!=id).toString()
              return i
            })
          }
          this.cartList()
          this.$store.dispatch('cartAmount')
          this.$message.success(res.message)
          this.$nextTick(() => {
            setTimeout(() => {
              this.handleFixedTops()
            }, 200)
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    cartIznvalidDel () {
      const data = cookie.get('checkEntity')
      if (!data || (data.entityCharacter === 'S' && data.entityId)) {
        this.$message.error('请切换对应买家身份进行操作！')
        return
      }
      cartIznvalidDel({purchaserId: (cookie.get('checkEntity') || {}).entityId}).then(res => {
         if (res.code === 200) {
          this.cartList()
          this.$store.dispatch('cartAmount')
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    isInclude(aa, bb) {
      return aa.every((item) => {
        return bb.some((sub) => {
          return sub === item;
        });
      });
    },
    allSelect (e) {
      if (e.target.checked) {
        this.selectedRowKeys = this.scList.map(item => item.id)
      } else {
        this.selectedRowKeys = []
        this.selectedCustomRowKeys = this.selectedCustomRowKeys.map(() => '')
      }
    },
    selectedCustom(e, r) {
      const row = Object.assign({}, r)
      // console.log(this.selectedRowKeys, this.scList);
      // console.log(1000, this.selectedCustomRowKeys);
      const selectedCustomRowKeys = this.selectedCustomRowKeys.map(item => {
        return item && item.split(',') || []
      })
      // console.log(selectedCustomRowKeys);

      // 触发计算属性 
      this.selectedCustomRowKeys.push('-1')
      // 按钮反馈
      if (e.target.checked) {
        this.$forceUpdate()
        selectedCustomRowKeys[row.index].push(row.id)
        this.selectedCustomRowKeys[row.index] = selectedCustomRowKeys[row.index].toString()
        // console.log('全部', row.ids, selectedCustomRowKeys);
        if (this.isInclude(row.ids, selectedCustomRowKeys[row.index])) {
          this.selectedRowKeys.push(row.ids.toString() + ',')
          // console.log('自动按钮', this.selectedRowKeys, selectedCustomRowKeys[row.index].toString() + ',');
          this.selectedCustomRowKeys[row.index] = ''
          this.selectedRows = [...this.selectedRows, { index: r.index, id: r.ids.toString() + ',' }]
        }
      } else {
        this.$forceUpdate()
        let isInclude = false
        this.selectedRowKeys.forEach((item, index) => {
          // item.indexOf(row.id + ',') > -1
          if (item.split(',').includes(row.id)) {
            this.selectedCustomRowKeys[row.index] = item.split(',').filter(i => i && i != row.id).toString()
            isInclude = true
            this.selectedRowKeys.splice(index, 1)
          }
        })
        if (!isInclude) {
          this.selectedCustomRowKeys[row.index] = selectedCustomRowKeys[row.index].filter(i => i && i != row.id).toString()
        }
      }
    },
    handleFixedTops() {
      // console.log(this.$refs.cart.$el.getBoundingClientRect().bottom, document.body.offsetHeight);
      if (this.$refs.cart.$el.getBoundingClientRect().bottom > document.body.offsetHeight) {
        this.fixed = true
      } else {
        this.fixed = false
      }
    },
    toDetail (id) {
      this.$router.push('/product-details?id=' + id)
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  max-width: 1440px;
  min-width: 990px;
  margin: 0 auto;
  padding: 0 15px;
}
.container-title {
  margin: 0 auto;
  width: 1100px;
  @media screen and (max-width: 1130px) {
    width: 940px;
  }
}
.container {
  // background: #fff;
  color: rgba(0, 0, 0, 0.85);
  margin: 0 auto;
  margin-bottom: 64px;
  width: 1100px;
  @media screen and (max-width: 1130px) {
    width: 940px;
  }
  .mdse-css {
    display: flex;
    img {
      width: 70px;
      height: 70px;
      display: inline-block;
      margin-right: 12px;
    }
    .square {
      background-color: #fff;
      margin-right: 12px;
      cursor: pointer;
    }
    .name {
      color: #555555;
      margin-bottom: 6px;
      a {
        color: #555555;
        &:hover {
          color: #60a830;
        }
      }
    }
    .hint {
      color: #aaaaaa;
    }
  }
  .sum {
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    color: #b8741a;
  }
  .f24 {
    font-size: 24px;
  }
  .f20 {
    font-size: 20px;
  }
  .space-end {
    align-items: flex-end;
  }
  .fixed {
    position: fixed;
    bottom: 0;
    width: inherit;
    z-index: 99;
  }
}
.invalid {
  margin-top: 24px;
  /deep/ .ant-table-row-level-1 {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(245, 245, 245, 0.46);
    }
    &:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: transparent;
    }
  }
}
/deep/ .ant-card-head-title {
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  color: #333333;
}
/deep/ .typesss .ant-checkbox-inner {
  &::after {
    transition: all .1s cubic-bezier(.71,-.46,.88,.6),opacity .1s;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: #60a830;
    border: 0;
    transform: translate(-50%,-50%) scale(1);
    opacity: 1;
    content: " ";
  }
  
}
.handle {
  border-top: 0;
}
</style>
