import request from '@/utils/request'


// 订单确认页接口
export function confirm(data) {
    return request({
        url: '/orders/merchandise/preview',
        method: 'post',
        data
    })
}
// 新增供应商收货地址
export function createAddress(data) {
    return request({
        url: '/trade/purchaser/addr/create',
        method: 'post',
        data
    })
}
//采购商收货地址列表
export function list(params) {
    return request({
        url: '/trade/purchaser/addr/list',
        method: 'get',
        params
    })
}
// 更新采购商收货地址
export function updateAddress(data) {
    return request({
        url: '/trade/purchaser/addr/update',
        method: 'post',
        data
    })
}
//获取采购商默认地址
export function getdefault(params) {
    return request({
        url: '/trade/purchaser/addr/default',
        method: 'get',
        params
    })
}
// 设置为默认收货地址
export function setdefault(data) {
    return request({
        url: '/trade/purchaser/addr/set/default',
        method: 'post',
        data
    })
}
// 删除采购商地址
export function deleteAddress(data) {
    return request({
        url: '/trade/purchaser/addr/delete',
        method: 'post',
        data
    })
}
// 订单生成
export function createOrder(data) {
    return request({
        url: '/orders/merchandise/create',
        method: 'post',
        data
    })
}
// 订单列表页数据获取
export function orderList({ searchText, riskControlStatus, invoiceStatusArray, logisticsStatus, contractStatus, paymentStatus, beginTime, endTime, supplierId, purchaserId, pageNum, pageSize }) {
    return request({
        url: '/orders/list',
        method: 'get',
        params: { searchText, invoiceStatusArray, riskControlStatus, logisticsStatus, beginTime, contractStatus, paymentStatus, endTime, supplierId, purchaserId, pageNum, pageSize }
    })
}

/**
 * @param {*} data 参数
 * @returns 订单成本申报
 */
 export function ordersCostdec(data) {
    return request({
        url: `/orders/costdec`,
        method: 'post',
        data
    })
}

/**
 * @param {*} data 参数
 * @returns 订单成本估算
 */
 export function ordersCostdecEval(data) {
    return request({
        url: `/orders/costdec/eval`,
        method: 'post',
        data
    })
}

/**
 * @param {*} data 参数
 * @returns 成本申报查询
 */
 export function ordersCostdecQuery(data) {
    return request({
        url: `/orders/costdec/query`,
        method: 'post',
        data
    })
}

/**
 * @param {*} orderId 参数
 * @returns 个人申请列表
 */
export function infoflow(orderId) {
    return request({
        url: `/orders/${orderId}/infoflow`,
        method: 'get'
    })
}

//票流详情
export function detail(params) {
    return request({
        url: '/trade/invoice/stream/detail',
        method: 'get',
        params
    })
}
// 申请开票
export function apply(data) {
    return request({
        url: '/trade/invoice/apply',
        method: 'post',
        data
    })
}
// 合同列表
export function findcontract(orderId) {
    return request({
        url: '/contract/findcontract',
        method: 'get',
        params: { orderId }
    })
}
// 合同重发短信
export function send(id, entityCharacter) {
    return request({
        url: '/contract/send',
        method: 'get',
        params: { id, entityCharacter }
    })
}

// 合同删除重发
export function anew(id) {
        return request({
            url: '/contract/anew',
            method: 'get',
            params: { id }
        })
    }
// 合同下载
export function download(id) {
    return request({
        url: '/contract/download',
        method: 'get',
        params: { id }
    })
}
// 电子凭证下载
export function voucher(id,entityCharacter) {
    return request({
        url: '/contract/download/voucher',
        method: 'get',
        params: { id,entityCharacter }
    })
}
// 添加物流信息
export function addLogistics(data) {
    return request({
        url: '/tax/logistics/add',
        method: 'post',
        data
    })
}
// 添加物流图片
export function addImg(data) {
    return request({
        url: '/tax/logistics/add/img',
        method: 'post',
        data
    })
}
// 返回订单收发货地址
export function getAddress(orderId) {
    return request({
        url: '/tax/logistics/address',
        method: 'get',
        params: {orderId}
    })
}

// 订单货流获取商品地址
export function getAddrFull(orderId) {
    return request({
        url: '/tax/logistics/getAddrFull',
        method: 'get',
        params: {orderId}
    })
}

// 物流信息列表
export function getLogistics(params) {
    return request({
        url: '/tax/logistics/list',
        method: 'get',
        params
    })
}
// 物流信息删除
export function delLogistics(data) {
    return request({
        url: '/tax/logistics/del',
        method: 'post',
        data
    })
}
// 物流信息图片删除
export function delImg(data) {
    return request({
        url: '/tax/logistics/del/img',
        method: 'post',
        data
    })
}
// 物流信息图片获取
export function getImg(orderLogisticsId) {
    return request({
        url: '/tax/logistics/img/list',
        method: 'get',
        params: {orderLogisticsId}
    })
}
// 物流信息进度
export function schedule(params) {
    return request({
        url: '/tax/logistics/schedule',
        method: 'get',
        params
    })
}
// 编辑物流信息
export function edit(data) {
    return request({
        url: '/tax/logistics/edit',
        method: 'post',
        data
    })
}
// 编辑物流图片
export function editImg(data) {
    return request({
        url: '/tax/logistics/edit/img',
        method: 'post',
        data
    })
}

// 编辑物流图片
export function ordersClose(orderId) {
    return request({
        url: `/orders/${orderId}/close`,
        method: 'post'
    })
}

// 导出发票信息
export function downloadExport(params) {
    return request({
        url: `/trade/invoice/info/download/export`,
        method: 'get',
        params,
        responseType: 'blob'
    })
}

// 下载发票
export function picDownload(params) {
    return request({
        url: `/trade/invoice/pic/download`,
        method: 'get',
        params,
        responseType: 'blob'
    })
}

// 订单修改收货地址
export function addressUpdate(data) {
    return request({
        url: `/orders/purchaser/address/update`,
        method: 'post',
        data
    })
}

// 批量订单开票申请
export function applyInvoice(data) {
    return request({
        url: '/trade/invoice/apply/list',
        method: 'post',
        data
    })
}

// 修改订单详情对应下游
export function updateDownstream (data) {
    return request({
        url: `/orders/update/downstream`,
        method: 'post',
        data
    })
}

// 订单列表页数据导出excel
export function ordersDownload(params) {
    return request({
        url: '/orders/list/download',
        method: 'get',
        params
    })
}

// 删除合同
export function deleteContract (data) {
    return request({
        url: `/contract/delete`,
        method: 'post',
        data
    })
  }

  // 添加和同
export function contractOffline (data) {
    return request({
        url: '/contract/offline/upload',
        method: 'post',
        data
    })
  }

  // 订单风控信息获取，订单五流信息完善度
  export function ordersRiskCR (data) {
    return request({
        url: '/orders/riskCR',
        method: 'post',
        data
    })
  }


// 入库单列表
export function warehouseList(params) {
    return request({
        url: '/tax/logistics/warehouse/in/list',
        method: 'get',
        params
    })
}

// 入库单图片
export function warehouseImg(params) {
    return request({
        url: '/tax/logistics/warehouse/in/img/list',
        method: 'get',
        params
    })
}

// 入库单关联
export function warehouseBind(data) {
    return request({
        url: '/tax/logistics/warehouse/in/bind',
        method: 'post',
        data
    })
}

// POI搜索地址
export function logisticsPoiSearch(params) {
    return request({
        url: '/tax/logistics/poi/search',
        method: 'get',
        params
    })
}

// 物流详情
export function logisticsDetail (params) {
    return request({
        url:'tax/logistics/detail',
        method: 'get',
        params
    })
  }

  /**
 * @param {*} parameter 参数
 * @returns 新增运输工具
 */
 export function transportAdd (data) {
    return request({
      url: '/tax/log/transport/add',
      method: 'post',
      data
    })
  }
  
  /**
   * @param {*} parameter 参数
   * @returns 编辑运输工具
   */
   export function transportUpdate (data) {
    return request({
      url: '/tax/log/transport/update',
      method: 'post',
      data
    })
  }
  
  /**
   * @param {*} parameter 参数
   * @returns 新增运输工具
   */
   export function transportOrderUpdate (data) {
    return request({
      url: '/tax/log/transport/order/update',
      method: 'post',
      data
    })
   }